import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { flatten, keys, pickBy, path, uniq, pluck, apply, range, map, pipe, filter, prop, not, flip, contains } from 'ramda'
import { getTheDevice, getAstroValue, getDeviceCoords, countDecimals, getUserUnitI, toFixed, isSomething, fcontains, removeFromArrWith, removeFromArr, addPropToObj, convertUnitForUser, convertUnitForUserOnRow, feelsLikeObj, dewPoint, pathsChanged, getTheme } from '../../common/ambient'
import { deviceIsAKestrel, simpleGraphs } from '../../common/lib/ambient-weather-common/ambient'
import {
  RainGraph,
  GenericGraph, WindDirectionGraph,
  WindGraph,
  TempGraph,
  SunMoonGraph,
  LightningGraph
} from './'
import { ReferenceLine } from 'recharts'
import Loader from '../../components/Loader'
import astro from '../../common/lib/astro'

const PM25_TEST = false

export default class DeviceDataGraphWrap extends PureComponent {
  static propTypes = {
    mode: PropTypes.string,
    activeGraphs: PropTypes.array,
    data: PropTypes.array,
    hideKeys: PropTypes.array,
    markerTime: PropTypes.number, // timestamp to show a line on the graphs
    onGraphClick: PropTypes.func,
    shortGraphs: PropTypes.bool, // shorter graphs
    user: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    deviceActions: PropTypes.object.isRequired,
    pending: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {}
  }

  _checkHash () {
    const { deviceActions, data, device } = this.props
    const { graphHash } = device
    if (!data) return

    if (graphHash) {
      const el = document.getElementById(`${graphHash}_graph`)
      if (el) {
        deviceActions.setThing('graphHash', false)
        clearTimeout(this._tout)
        this._tout = setTimeout(() => {
          document.getElementById(`${graphHash}_graph`) && document.getElementById(`${graphHash}_graph`).scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }, 2000)
      }
    }
  }

  componentDidMount () {
    this._checkHash()
    this._processGraphData()
  }

  componentDidUpdate (prevProps) {
    this._checkHash()
    if (pathsChanged(this.props, prevProps, [['data']])) {
      this._processGraphData()
    }
  }

  _processGraphData () {
    const { data, user, mode, device } = this.props
    let graphData = data && Array.isArray(data) ? data.slice(0) : []
    if (graphData.length > 0) {
      const convertParam = convertUnitForUserOnRow(user)
      const convertUnit = convertUnitForUser(user)
      const theDevice = getTheDevice(device)
      const coords = getDeviceCoords(device) // device has lat lon
      const adds = pipe(
        doc => {
          if (coords) {
            doc.sun = astro.solar.sin_elevation(doc.dateutc, coords.lat, coords.lon)
            doc.moon = astro.lunar.elevation(doc.dateutc, coords.lat, coords.lon)
            if (doc.sun < 0) doc.sun = 0
            if (doc.moon < 0) doc.moon = 0
            if (mode === 'yearly') {
              doc.moon = 0
              const thisDay = moment.tz(doc.dateutc, theDevice.tz.name).startOf('day').valueOf()
              const eod = moment.tz(doc.dateutc, theDevice.tz.name).endOf('day')
              const sunRiseM = getAstroValue(moment, theDevice, astro.solar.rise, eod)
              const sunSetM = getAstroValue(moment, theDevice, astro.solar.set, eod)
              doc.sunTransitM = getAstroValue(moment, theDevice, astro.solar.transit, eod)
              doc.sunRiseSet = [sunRiseM.valueOf() - thisDay, sunSetM.valueOf() - thisDay]
              doc.sunTransit = doc.sunTransitM.valueOf() - thisDay
            }
          }
          return doc
        },
        addPropToObj('dewPoint', obj => dewPoint(obj.tempf, obj.humidity)),
        addPropToObj('feelsLike', feelsLikeObj),
        convertParam('tempf'),
        convertParam('temp1f'),
        convertParam('temp2f'),
        convertParam('temp3f'),
        convertParam('temp4f'),
        convertParam('temp5f'),
        convertParam('temp6f'),
        convertParam('temp7f'),
        convertParam('temp8f'),
        convertParam('temp9f'),
        convertParam('temp10f'),
        convertParam('soiltemp1'),
        convertParam('soiltemp2'),
        convertParam('soiltemp3'),
        convertParam('soiltemp4'),
        convertParam('soiltemp5'),
        convertParam('soiltemp6'),
        convertParam('soiltemp7'),
        convertParam('soiltemp8'),
        convertParam('soiltemp9'),
        convertParam('soiltemp10'),
        convertParam('soiltemp11'),
        convertParam('soiltemp12'),
        convertParam('soiltemp13'),
        convertParam('soiltemp14'),
        convertParam('soiltemp15'),
        convertParam('soiltemp16'),
        convertParam('tempinf'),
        convertParam('feelsLike'),
        convertParam('dewPoint'),
        convertParam('windspeedmph'),
        convertParam('windgustmph'),
        convertParam('hourlyrainin'),
        convertParam('dailyrainin'),
        convertParam('baromrelin'),
        convertParam('solarradiation'),
        convertParam('pm_in_temp_aqin'),
        convertParam('soiltens1'),
        convertParam('soiltens2'),
        convertParam('soiltens3'),
        convertParam('soiltens4'),
        convertParam('wbgtf'),
        convertParam('heatindexf'),
        convertParam('lightning_distance'),
        convertParam('pig'),

        pickBy(val => {
          return Array.isArray(val) || !isNaN(val)
        })
      )
      graphData = map(adds, graphData)
    }
    this.setState({
      graphData
    })
  }

  render () {
    const { device, user, data, hideKeys, mode, activeGraphs, markerTime, onGraphClick, shortGraphs } = this.props
    const { width, height, graphData } = this.state
    const isAKestrel = deviceIsAKestrel(device.dashboardDevice)
    let message = <Loader />
    if (data && graphData) {
      if (graphData.length > 0 &&
          width &&
          height
      ) {
        const coords = getDeviceCoords(device) // device has lat lon
        let videoLine
        if (isSomething(markerTime)) {
          const i = graphData.findIndex(d => markerTime >= d.dateutc)
          videoLine = isSomething(i) && <ReferenceLine x={path(['dateutc'], graphData[i])} stroke={getTheme(user) === 'dark' ? 'white' : 'black'} />
        }
        const onClick = onGraphClick && (evt => {
          if (!evt) return
          if (evt.activeLabel) {
            let d = new Date(parseInt(evt.activeLabel, 10))
            if (!isNaN(d.getTime())) {
              const data = graphData.find(doc => d.getTime() >= doc.dateutc)
              onGraphClick({
                evt,
                timestamp: d.getTime(),
                data
              })
            }
          }
        })
        const graphHeights = {
          full: height,
          med: Math.ceil((height / 5) * 3),
          short: Math.ceil(height / 2)
        }
        message = []
        if (isAKestrel) {
          message.push(
            <GenericGraph
              key={'tempf'}
              dataKey={'tempf'}
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              mode={mode}
              user={user}
            />
          )
        } else {
          message.push(
            <TempGraph
              key='temp'
              width={width}
              height={shortGraphs ? graphHeights.short : graphHeights.full}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              activeGraphs={removeFromArr('tempinf', activeGraphs)}
              user={user}
            >{videoLine}
            </TempGraph>
          )
        }

        message.push(
          <WindGraph
            key='wind'
            width={width}
            height={shortGraphs ? graphHeights.short : graphHeights.med}
            graphData={graphData}
            onClick={onClick}
            mode={mode}
            activeGraphs={activeGraphs}
            user={user}
          >{videoLine}
          </WindGraph>,
        )

        message.push(
          <WindDirectionGraph
            key="winddir"
            width={width}
            height={shortGraphs ? graphHeights.short : graphHeights.med}
            graphData={graphData}
            onClick={onClick}
            mode={mode}
            activeGraphs={activeGraphs}
            user={user}
          >{videoLine}
          </WindDirectionGraph>
        )

        const getPM10Color = val => {
          if (!isSomething(val)) return
          if (val <= 55) return '#199969'
          if (val < 155) return '#fdde4f'
          if (val < 255) return '#fc9a44'
          if (val < 355) return '#c90335'
          if (val < 425) return '#650095'
          return '#65021e'
        }
        const getCo2Color = val => {
          if (!isSomething(val)) return
          if (val <= 600) return '#199969'
          if (val < 1000) return '#fdde4f'
          if (val < 1500) return '#fc9a44'
          if (val < 2000) return '#c90335'
          if (val < 2500) return '#650095'
          return '#65021e'
        }
        const getPM25Color = val => {
          if (!isSomething(val)) return
          if (val <= 12) return '#199969'
          if (val < 35.5) return '#fdde4f'
          if (val < 55.5) return '#fc9a44'
          if (val < 150.5) return '#c90335'
          if (val < 250.5) return '#650095'
          return '#65021e'
        }
        const pm25Maxes = {
          pm25_24h: apply(Math.max, filter(isSomething, pluck('pm25_24h', graphData))),
          pm25_in_24h: apply(Math.max, filter(isSomething, pluck('pm25_in_24h', graphData))),
          pm10_in_24h: apply(Math.max, filter(isSomething, pluck('pm10_in_24h', graphData))),
          co2_in_aqin: apply(Math.max, filter(isSomething, pluck('co2_in_aqin', graphData))),
          co2_in: apply(Math.max, filter(isSomething, pluck('co2_in_aqin', graphData)))
        }
        const stopsForParam = param => {
          let colorFn = getPM25Color
          if (/pm10/.test(param)) {
            colorFn = getPM10Color
          } else if (/co2/.test(param)) {
            colorFn = getCo2Color
          }
          let val = colorFn(0)
          const ret = [<stop offset='0%' stopColor={val} key={param + '0'} />]
          const paramMax = pm25Maxes[param]
          range(0, paramMax + 1).forEach(v => {
            const newVal = colorFn(v)
            if (newVal !== val) {
              val = newVal
              ret.push(<stop offset={(v / paramMax * 100) + '%'} stopColor={val} key={param + val} />)
            }
          })
          return ret
        }
        if (contains('co2_in', activeGraphs)) {
          message.push(
            <GenericGraph
              key="co2_in"
              dataKey="co2_in"
              id="co2_in"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              stroke="url(#co2)"
              domain={['dataMin', 'dataMax']}
            >
              <defs>
                <linearGradient id='co2' x1='0%' y1='100%' x2='0%' y2='0%'>
                  {stopsForParam('co2_in')}
                </linearGradient>
              </defs>
            </GenericGraph>
          )
        }
        if (contains('co2_in_aqin', activeGraphs)) {
          message.push(
            <GenericGraph
              key="co2_in_aqin"
              dataKey="co2_in_aqin"
              id="co2"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              stroke="url(#co2)"
              domain={['dataMin', 'dataMax']}
            >
              <defs>
                <linearGradient id='co2' x1='0%' y1='100%' x2='0%' y2='0%'>
                  {stopsForParam('co2_in_aqin')}
                </linearGradient>
              </defs>
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('pm25', activeGraphs)) {
          message.push(
            <GenericGraph
              key="pm25"
              dataKey="pm25"
              id="air"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              mode={mode}
              user={user}
              domain={[0, 'dataMax']}
            >
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('pm25_24h', activeGraphs)) {
          message.push(
            <GenericGraph
              key="pm25_24h"
              dataKey="pm25_24h"
              id="air_24"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              stroke="url(#pm2524)"
              domain={[0, 'dataMax']}
            >
              <defs>
                <linearGradient id='pm2524' x1='0%' y1='100%' x2='0%' y2='0%'>
                  {stopsForParam('pm25_24h')}
                </linearGradient>
              </defs>
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('pm25_in_aqin', activeGraphs)) {
          message.push(
            <GenericGraph
              key="pm25_in_aqin"
              dataKey="pm25_in_aqin"
              id="aqin"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              domain={[0, 'dataMax']}
            >
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('pm25_in_24h_aqin', activeGraphs)) {
          message.push(
            <GenericGraph
              key="pm25_in_24h_aqin"
              dataKey="pm25_in_24h_aqin"
              id="pm25_in_24h_aqin"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              stroke="url(#pm2524aqin)"
              domain={[0, 'dataMax']}
            >
              <defs>
                <linearGradient id='pm2524aqin' x1='0%' y1='100%' x2='0%' y2='0%'>
                  {stopsForParam('pm25_in_24h_aqin')}
                </linearGradient>
              </defs>
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('pm10_in_aqin', activeGraphs)) {
          message.push(
            <GenericGraph
              key="pm10_in_aqin"
              dataKey="pm10_in_aqin"
              id="pm10_in_aqin"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              domain={[0, 'dataMax']}
            >
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('pm10_in_24h_aqin', activeGraphs)) {
          message.push(
            <GenericGraph
              key="pm10_in_24h_aqin"
              dataKey="pm10_in_24h_aqin"
              id="pm10_in_24h_aqin"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              stroke="url(#pm1024aqin)"
              domain={[0, 'dataMax']}
            >
              <defs>
                <linearGradient id='pm1024aqin' x1='0%' y1='100%' x2='0%' y2='0%'>
                  {stopsForParam('pm10_in_24h_aqin')}
                </linearGradient>
              </defs>
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('rain', activeGraphs)) {
          message.push(
            <RainGraph
              key="rain"
              width={width}
              height={shortGraphs ? graphHeights.short : graphHeights.med}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
            >
              {videoLine}
            </RainGraph>
          )
        }
        if (contains('baromrelin', activeGraphs)) {
          const baromUserUnitI = getUserUnitI('baromrelin', user)
          message.push(
            <GenericGraph
              key="pressure"
              id="pressure"
              dataKey="baromrelin"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              tickFormatter={d => {
                if (baromUserUnitI === 0) {
                  return toFixed(2, d)
                }
                return toFixed(0, d)
              }}
            >
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('pm_in_humidity_aqin', activeGraphs)) {
          message.push(
            <GenericGraph
              key="pm_in_humidity_aqin"
              dataKey="pm_in_humidity_aqin"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              domain={[0, 100]}
            >
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('humidity', activeGraphs)) {
          message.push(
            <GenericGraph
              key="humidity"
              dataKey="humidity"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              domain={[0, 100]}
            >{videoLine}
            </GenericGraph>
          )
        }
        if (contains('solarradiation', activeGraphs)) {
          message.push(
            <GenericGraph
              key="solar"
              dataKey="solarradiation"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              tickFormatter={d => {
                if (d > 1000) {
                  return Math.floor(d / 1000) + 'k'
                }
                return Math.round(d)
              }}
              user={user}
            >{videoLine}
            </GenericGraph>
          )
        }
        if (contains('uv', activeGraphs)) {
          message.push(
            <GenericGraph
              key="uv"
              dataKey="uv"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
            >{videoLine}
            </GenericGraph>
          )
        }
        if (contains('lightning', activeGraphs)) {
          message.push(
            <LightningGraph
              key="lightning"
              width={width}
              height={shortGraphs ? graphHeights.short : graphHeights.med}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
            >
              {videoLine}
            </LightningGraph>
          )
        }
        if (contains('tempinf', activeGraphs)) {
          message.push(
            <TempGraph
              key="tempinf"
              width={width}
              height={shortGraphs ? graphHeights.short : graphHeights.med}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              activeGraphs={removeFromArrWith(fcontains(['feelsLike', 'dewPoint', 'tempf']), activeGraphs)}
              user={user}
            >
              {videoLine}
            </TempGraph>
          )
        }
        if (contains('humidityin', activeGraphs)) {
          message.push(
            <GenericGraph
              key="humidityin"
              dataKey="humidityin"
              id='indoor'
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
            >{videoLine}
            </GenericGraph>
          )
        }
        if (contains('pm25_in', activeGraphs)) {
          message.push(
            <GenericGraph
              key="pm25_in"
              dataKey="pm25_in"
              id="airin"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              stroke="url(#pm25_in)"
              domain={[0, 'dataMax']}
            >
              <defs>
                <linearGradient id='pm25_in' x1='0%' y1='100%' x2='0%' y2='0%'>
                  {stopsForParam('pm25_in')}
                </linearGradient>
              </defs>
              {videoLine}
            </GenericGraph>
          )
        }
        if (contains('pm25_in_24h', activeGraphs)) {
          message.push(
            <GenericGraph
              key="pm25_in_24h"
              dataKey="pm25_in_24h"
              id="air_in_24"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              stroke="url(#pm25_in24)"
              domain={[0, 'dataMax']}
            >
              <defs>
                <linearGradient id='pm25_in24' x1='0%' y1='100%' x2='0%' y2='0%'>
                  {stopsForParam('pm25_in_24h')}
                </linearGradient>
              </defs>
              {videoLine}
            </GenericGraph>
          )
        }
        if (!isAKestrel && coords && contains('sunMoon', activeGraphs)) {
          message.push(
            <SunMoonGraph
              key="sunMoon"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              device={device}
            >{videoLine}
            </SunMoonGraph>
          )
        }
        const getAllKeys = pipe(
          map(keys),
          flatten,
          uniq
        )
        const allKeys = getAllKeys(graphData)
        range(1, 17).forEach((i) => {
          [`temp${i}f`, `humidity${i}`, `soiltemp${i}`, `soilhum${i}`, `soiltens${i}`].forEach(key => {
            const hasKey = fcontains(allKeys)
            if (contains(key, activeGraphs) && (hasKey(key) || hasKey(key + 'Hl'))) {
              message.push(
                <GenericGraph
                  key={key}
                  dataKey={key}
                  width={width}
                  height={Math.ceil(height / 2)}
                  graphData={graphData}
                  onClick={onClick}
                  mode={mode}
                  user={user}
                  tickFormatter={d => {
                    if (/temp/.test(key) && countDecimals(d) > 1) {
                      return d.toFixed(1)
                    }
                    return d
                  }}
                >{videoLine}
                </GenericGraph>
              )
            }
          })
        })
        if (contains('leafwetsum', activeGraphs)) {
          const wetTimeUserUnitI = getUserUnitI('leafwetsum', user)
          message.push(
            <GenericGraph
              key="leafwetsum"
              id="leafwetsum"
              dataKey="leafwetsum"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              tickFormatter={d => {
                if (wetTimeUserUnitI === 0) {
                  return toFixed(2, d)
                }
                return toFixed(0, d)
              }}
            >{videoLine}
            </GenericGraph>
          )
        }
        simpleGraphs.forEach(key => {
          if (contains(key, activeGraphs)) {
            message.push(
              <GenericGraph
                key={key}
                dataKey={key}
                width={width}
                height={Math.ceil((height / 5) * 3)}
                graphData={graphData}
                mode={mode}
                user={user}
              />
            )
          }
        })
        if (contains('dischargeft3s', activeGraphs)) {
          message.push(
            <GenericGraph
              key={'dischargeft3s'}
              dataKey={'dischargeft3s'}
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              mode={mode}
              user={user}
            />
          )
        }
        if (contains('gaugeheightft', activeGraphs)) {
          message.push(
            <GenericGraph
              key={'gaugeheightft'}
              dataKey={'gaugeheightft'}
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              mode={mode}
              user={user}
            />
          )
        }
        if (contains('watermeasph', activeGraphs)) {
          message.push(
            <GenericGraph
              key={'watermeasph'}
              dataKey={'watermeasph'}
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              mode={mode}
              user={user}
            />
          )
        }
        if (contains('waternitratesmgl', activeGraphs)) {
          message.push(
            <GenericGraph
              key={'waternitratesmgl'}
              dataKey={'waternitratesmgl'}
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              mode={mode}
              user={user}
            />
          )
        }
        if (contains('gdd', activeGraphs)) {
          message.push(
            <GenericGraph
              key="gdd"
              dataKey="gdd"
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
            >{videoLine}
            </GenericGraph>
          )
        }
        if (contains('etos', activeGraphs)) {
          message.push(
            <GenericGraph
              key='etos'
              dataKey='etos'
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              tickFormatter={toFixed(2)}
            >{videoLine}
            </GenericGraph>
          )
        }
        if (contains('etrs', activeGraphs)) {
          message.push(
            <GenericGraph
              key='etrs'
              dataKey='etrs'
              width={width}
              height={Math.ceil(height / 2)}
              graphData={graphData}
              onClick={onClick}
              mode={mode}
              user={user}
              tickFormatter={toFixed(2)}
            >{videoLine}
            </GenericGraph>
          )
        }

        if (hideKeys.length > 0) {
          const notInHideKeys = pipe(
            prop('key'),
            flip(contains)(hideKeys),
            not
          )
          message = filter(notInHideKeys, message)
        }
      }
    }
    return (
      <div
        ref={(el) => {
          if (!el || this.state.width) {
            return
          }
          this.setState({ width: el.offsetWidth, height: Math.ceil(el.offsetWidth / 3) })
        }}
        className='device-device-data-graph-wrap'
      >
        {message}
      </div>
    )
  }
}

DeviceDataGraphWrap.displayName = 'DeviceDataGraphWrap'
