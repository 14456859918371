import PropTypes from 'prop-types'
import React, { useRef, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import classNames from 'classnames'
import Linkify from 'linkifyjs/react'
import { postIsExpired, trackEvent, camId2Slug } from '../../common/ambient'
import bindUserActions from '../user/redux/bindUserActions'
import { EveryWidget } from '../device'
import Tags from '../common/Tags'

// Static cache for image heights
const imageHeightCache = {};

const Post = ({ post = {}, actions: userActions }) => {
  const [state, setState] = useState({
    showMore: false,
    imgLoaded: true,
    imgHeight: null,
    portrait: false
  })
  
  const imageRef = useRef(null)
  const containerRef = useRef(null)

  // Try to set cached height on mount
  useEffect(() => {
    if (post.image && imageHeightCache[post.image]) {
      setState(prev => ({
        ...prev,
        imgHeight: imageHeightCache[post.image],
        imgLoaded: true
      }));
    } else if (containerRef.current && post.image && !post.video) {
      // Set default height if no cache exists
      const width = containerRef.current.clientWidth;
      setState(prev => ({
        ...prev,
        imgHeight: width * (2/3)
      }));
    }
  }, [post.image, post.video]);

  const handleImageLoad = (e) => {
    if (!e || !e.target || !imageRef.current) {
      return;
    }

    const imgElement = e.target;
    const width = imageRef.current.clientWidth;
    const maxHeight = (width / 3) * 4;
    
    let height = width * (imgElement.naturalHeight / imgElement.naturalWidth);
    if (height > maxHeight) {
      height = maxHeight;
    }

    // Cache the height for this image URL
    if (post.image && width) {
      imageHeightCache[post.image] = height;
    }

    setState(prev => ({
      ...prev,
      imgLoaded: true,
      imgHeight: height,
      portrait: imgElement.naturalHeight > imgElement.naturalWidth
    }));
  };

  const tileSnapshot = () => {
    if (!/widget/.test(post.type)) return null;
    const widgetType = post.type.replace('widget-', '');
    const data = post.data || {};
    return (
      <EveryWidget
        snapshot
        type={widgetType}
        {...data}
      />
    );
  };

  const { showMore, imgHeight, imgLoaded, portrait } = state;
  const showImage = post.image && !post.video;
  const imgUrl = post.image && post.image.replace('cdn.filestackcontent.com/', 'd2pr2a8f0ao4pf.cloudfront.net/cache=expiry:max/resize=width:1000,height:1000,fit:clip/compress/');

  return (
    <div 
      ref={containerRef}
      className={classNames('social-post', post.status, `type-${post.type}`, {
        'show-more': showMore,
        'no-text': !post.text,
        'has-text': post.text,
        'no-img': !post.image,
        'has-img': post.image,
        'has-more': (post.text && post.image) || tileSnapshot(),
        'img-loaded': imgLoaded,
        portrait,
        expired: postIsExpired(post)
      })}
      style={{
        minHeight: showImage ? '200px' : 'auto'
      }}
    >
      {post.tags && post.tags.length > 0 && <Tags tags={post.tags} />}
      
      {post.text && (
        <div className="text">
          <Linkify options={{ nl2br: true, target: { url: '_blank' } }}>
            {post.text}
          </Linkify>
        </div>
      )}

      {post.video && (
        <div className="video-container">
          <ReactPlayer
            className="video"
            url={post.video}
            light={post.image}
            controls
            muted
            loop
            width="100%"
            height="100%"
            onPlay={() => {
              trackEvent('webcam', 'play', camId2Slug(post.video.split('/').slice(-2, -1)[0].replace('.mp4', '')))
            }}
          />
        </div>
      )}

      {showImage && (
        <div ref={imageRef} className="image-container" style={{ position: 'relative' }}>
          <div 
            className="img img-cover"
            style={{
              backgroundImage: `url(${imgUrl})`,
              height: imageHeightCache[imgUrl] || imgHeight || '200px',
              cursor: 'pointer'
            }}
            onClick={() => userActions.doModal({ type: 'image', data: { url: imgUrl } })}
          />
          <img 
            src={imgUrl}
            className="ratio"
            style={{ 
              visibility: 'hidden',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%'
            }}
            alt=""
            onLoad={handleImageLoad}
          />
        </div>
      )}

      {tileSnapshot()}
      
      <a className="show-more" onClick={() => setState(prev => ({ ...prev, showMore: true }))}>
        show more...
      </a>
      
      <div className="expiration">
        {postIsExpired(post) ? '' : `Expires ${moment(post.expiresAt).fromNow()}`}
      </div>
    </div>
  );
};

Post.propTypes = {
  post: PropTypes.object,
  actions: PropTypes.object // userActions
};

export default bindUserActions(Post);
Post.displayName = 'Post';