// import io from 'socket.io-client'
import feathers from 'feathers/client'
// import socketio from 'feathers-socketio/client'
import rest from './lib/rest-client/lib'
import hooks from 'feathers-hooks'
import AuthManagement from 'feathers-authentication-management/lib/client'
import authentication from 'feathers-authentication/client'
import { omit, curry, pick, path } from 'ramda'
import { getUrl, isIosApp, isAndroidApp } from './ambient/utils'
const url = getUrl()

let storage
try {
  storage = window.localStorage
} catch (e) {

}
// const socket = io(url, { transports: ['websocket'], forceNew: true })
const restClient = rest(url)
export const app = feathers()
        .configure(hooks())
        .configure(authentication({
          storage
        }))
        // .configure(socketio(socket, { timeout: 10000 }))
        .configure(restClient.fetch(window.fetch))

export const authManagement = new AuthManagement(app)

let loggedIn = false

app.ambientLogin = function (creds) {
  if (loggedIn) {
    return loggedIn
  }
  return app.authenticate(creds)
    .then(function (result) {
      if (result && result.token) {
        try {
          if (isIosApp()) {
            window.webkit.messageHandlers.signInSuccesfullWebNotification.postMessage({ 
              token: result.token,
              userId: result.data._id
            });
          } else if (isAndroidApp()) {
            window.ANDROID.signInSuccesfullWebNotification(result.token, result.data._id);
          }
        } catch (e) {
          console.log('error', e)
        }
      }
      loggedIn = new Promise(function (resolve) {
        resolve(result)
      })
      return result
    })
}

app.ambientLogout = function () {
  loggedIn = false
  try {
    if (isIosApp()) {
      window.webkit.messageHandlers.signOutWebNotification.postMessage({});
    } else if (isAndroidApp()) {
      window.ANDROID.signOutWebNotification();
    }
  } catch (e) {
    console.log('error', e)
  }
  return app.logout()
}

// app.io.on('reconnect', function () {
//   app.authenticate()
// })

/**
 * actionsSettings = { begin, success, fail }
 */
export const servicePatch = curry(function (service, actionSettings, id, args) {
  const theService = app.service(service)
  let _tout = false
  return (dispatch) => {
    let ret = { id, args }
    dispatch({
      type: actionSettings.begin,
      data: ret
    })
    if (_tout) {
      clearTimeout(_tout)
    }
    return new Promise(function (resolve, reject) {
      _tout = setTimeout(() => {
        theService.patch(id, args)
          .then((res) => {
            ret.result = res
            dispatch({
              type: actionSettings.success,
              data: ret
            })
            resolve(ret)
          })
          .catch((err) => {
            dispatch({
              type: actionSettings.fail,
              data: {
                error: err
              }
            })
            reject(err)
          })
      }, 500)
    })
  }
})

/**
 * actionsSettings = { begin, success, fail, pagination }
 */
export const serviceFind = curry(function (service, actionSettings, query) {
  const theService = app.service(service)
  return (dispatch) => {
    const passThroughs = ['skipCache', 'dataKey']
    dispatch(Object.assign({
      type: actionSettings.begin
    }, pick(passThroughs, query)))
    return theService.find({ query: omit(passThroughs, query) })
      .then(function (res) {
        dispatch(Object.assign({
          type: actionSettings.success,
          data: Array.isArray(res) ? res : res.data
        }, pick(passThroughs, query)))
        if (actionSettings.pagination) {
          dispatch(Object.assign({
            type: actionSettings.pagination,
            data: omit(['data'], res)
          }, pick(passThroughs, query)))
        }
        return res
      })
      .catch(function (err) {
        dispatch({
          type: actionSettings.fail,
          data: {
            error: err
          }
        })
        return { data: [] }
      })
  }
})

/**
 * actionsSettings = { begin, success, fail }
 */
export const serviceCreate = curry(function (service, actionSettings, obj) {
  const theService = app.service(service)
  return (dispatch, getState) => {
    dispatch({
      type: actionSettings.begin, 
      data: obj
    })
    return theService.create(obj)
      .then(function (res) {
        dispatch({
          type: actionSettings.success,
          data: res
        })
        return res
      })
      .catch(function (err) {
        dispatch({
          type: actionSettings.fail,
          data: {
            obj,
            error: err
          }
        })
        return null
      })
  }
})

/**
 * actionsSettings = { begin, success, fail }
 */
export const serviceGet = curry(function (service, actionSettings, id) {
  const theService = app.service(service)
  return (dispatch, getState) => {
    dispatch({
      type: actionSettings.begin, 
      data: id
    })
    return theService.get(id)
      .then(function (res) {
        if (res) {
          dispatch({
            type: actionSettings.success,
            data: res
          })
        } else {
          dispatch({
            type: actionSettings.fail,
            data: {
              id,
              error: 'not found'
            }
          })
        }
        return res
      })
      .catch(function (err) {
        dispatch({
          type: actionSettings.fail,
          data: {
            id,
            error: err
          }
        })
        return null
      })
  }
})

/**
 * actionsSettings = { begin, success, fail }
 */
export const serviceUpdate = curry(function (service, actionSettings, id, data, params) {
  const theService = app.service(service)
  return (dispatch) => {
    dispatch({
      type: actionSettings.begin,
      data
    })
    return theService.update(id, data, params)
      .then(function (rev) {
        dispatch({
          type: actionSettings.success,
          data: { id, rev }
        })
      })
      .catch(function (err) {
        dispatch({
          type: actionSettings.fail,
          data: {
            error: err
          }
        })
      })
  }
})

/**
 * actionsSettings = { begin, success, fail }
 */
export const serviceRemove = curry(function (service, actionSettings, id) {
  const theService = app.service(service)
  return (dispatch, getState) => {
    dispatch({
      type: actionSettings.begin, 
      data: id
    })
    return theService.remove(id)
      .then(function (res) {
        dispatch({
          type: actionSettings.success,
          data: res
        })
        return res
      })
      .catch(function (err) {
        dispatch({
          type: actionSettings.fail,
          data: {
            id,
            error: err
          }
        })
        return null
      })
  }
})

export const rawFeathersRequest = async (pth, opts = {}) => {
  const url = `${getUrl()}${pth}`
  const args = Object.assign({
    method: 'GET',
    headers: {
      Authorization: `${window.localStorage.getItem('feathers-jwt')}`,
      'Content-Type': 'application/json'
    }
  }, opts)
  return fetch(url, args)
    .then(res => res.json())
    .catch(err => {
      console.log('err', err)
      return false
    })
}
