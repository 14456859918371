import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import bindDeviceActions from './redux/bindDeviceActions';
import {
  ProtectedLink
} from '../../components'

class LastUpdated extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    device: PropTypes.object,
    actions: PropTypes.object
  }
  constructor(props) {
    super(props)
    this.state = {
      now: moment()
    }
    this.interval = false
  }
  componentDidMount() {
    this.interval = setInterval(() => this.setState({ now: moment() }), 5000)
  }
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  render() {
    let countdown = <span className="countdown" />
    const { now } = this.state
    const { currentDevice, device, actions } = this.props
    const { fetchDevicesPending } = device
    const { fetchDevices } = actions

    const dev = currentDevice
    if (!dev || !dev.lastData) return null

    // KestrelMet WiFi suffers lag of 2 minutes, this makes it less obvious
    const absolute = dev.lastData.stationtype && dev.lastData.stationtype === "NK_KestrelMet6000W"  // W = WiFi

    if (fetchDevicesPending) {
      countdown = <span key={'countdown'} className="countdown">one moment...</span>
    } else if (dev.lastData.dateutc) {
      const t = moment(new Date(dev.lastData.dateutc))
      if (t.diff(new Date(), 'minutes') < -1) {
        countdown = <ProtectedLink
            key={'refresh'}
            onClick={fetchDevices}
            className="countdown"
          >{absolute ? t.format('MM/DD/YYYY, h:mm a') : t.from(now)}&nbsp;&nbsp; <i className="glyphicon glyphicon-refresh"/></ProtectedLink>
      } else {
        countdown = <span key={'countdown'} className="countdown">
            {absolute ? t.format('MM/DD/YYYY, h:mm a') : t.from(now)}
          </span>
      }
    }
    return countdown
  }
}

export default bindDeviceActions(LastUpdated)
LastUpdated.displayName = 'LastUpdated'

