import { ambient } from '../lib/ambient-weather-common'
import { useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Geocode from 'react-geocode'
import md5 from 'blueimp-md5'
import { splitEvery, prop, toPairs, join, merge, pluck, nth, find, equals, last, fromPairs, clone, reduce, sort, over, lensProp, values, uniq, flatten, map, pick, keys, contains, isArrayLike, all, F, groupWith, flip, allPass, append, path, curry, filter, pipe, propEq, not, groupBy } from 'ramda'
import { findLocationFromGoogleRes, isCordova, isBillingDev, what, isSomething, fcontainsAny, removeFromArr, getUrl, hasWebcam } from './utils'
import { windDirFromLabel, convertUnit } from './weather'
import astro from '../lib/astro'
import { MAP_LEGENDS } from './map'

export const FORECAST_ZOOM = 6.5
export const GOOGLE_API_KEY = 'AIzaSyAzKKOVP03RekNzRmZjc4aP2cv-MDRRl0U'
Geocode.setApiKey(GOOGLE_API_KEY)
export const getGeocode = () => Geocode

export const aeris = window.AerisWeather ? new AerisWeather('TbcOgeIC2eXyT0lIfKHkq', 'dBFC4IPvpiWR3MM7L8PJdJVLc8N8SCLeol88e696', {
  maps: 'https://d6hpc5frkn473.cloudfront.net',
  api: 'https://api.aerisapi.com'
}) : null
export const aerisData = (endpoint, query = {}) => {
  const q = pipe(
    toPairs,
    map(join('=')),
    join('&')
  )(query)
  return fetch(`https://api.aerisapi.com/${endpoint}/?client_id=TbcOgeIC2eXyT0lIfKHkq&client_secret=dBFC4IPvpiWR3MM7L8PJdJVLc8N8SCLeol88e696&${q}`)
    .then(res => res.json())
}

export const displayProps = ambient.getDisplayProps()
// modified for Front End
displayProps.feelsLike = {
  type: 'Float',
  label: 'Feels Like',
  units: 'tempf'
}
displayProps.dewPoint = {
  type: 'Float',
  label: 'Dew Point',
  units: 'tempf'
}
export const displayPropKeys = keys(displayProps)
export const METERS_TO_FEET = 3.28084

export * from './weather'
export * from './utils'
export * from './map'
export * from './social'
export * from './geo'
export * from './wxw'
export * from './graphing'

export function isLoggedIn (user) {
  return user.info && user.info._id
}
export const isEmbed = location => location.search && /embed=true/.test(location.search)

export const setStorage = (key, val) => {
  try {
    let ret = false
    if (window.localStorage) {
      ret = true
      window.localStorage[key] = JSON.stringify(val)
    }
    return ret
  } catch (e) {
  }
}
export const getStorage = key => {
  try {
    if (window.localStorage && window.localStorage[key]) {
      return JSON.parse(window.localStorage[key])
    }
  } catch (e) {
  }
}

export const clearStorage = () => {
  try {
    window.localStorage.clear()
  } catch (e) {

  }
}

export const OAUTH_CONFIG = {
  QGp6DjXad3cIAp2GiEuG2LZe23zrDD86OnKWrpCb: {
    name: 'IFTTT',
    redirect: 'https://ifttt.com/channels/ambient_weather' + (/herokuapp/.test(window.location.hostname) ? '_staging' : '') + '/authorize'
  },
  A4S9YtZ65YEI1ixPat0BDFMb25PZTl7gDdnfJ9Gg: {
    name: 'Alexa'
  },
  '739023693026-iqpn1v01871lk10megbunipkam5kksjv.apps.googleusercontent.com': {
    name: 'Google',
    redirect: /dev-fe/.test(window.location.host) ? 'https://oauth-redirect.googleusercontent.com/r/ambient-weather-v2-enndwg' : 'https://oauth-redirect.googleusercontent.com/r/ambient-weather',
    userOauthKey: 'google-assistant'
  }
}
export const ALERT_CONDITIONS = [
  ['is greater than', '>'],
  ['is less than', '<']
]
export const getConditionLabel = cond => nth(0, find(pipe(nth(1), equals(cond)), ALERT_CONDITIONS))

export function soilHumidityLabel (val) {
  let value = 'Very Dry'
  if (val > 87) {
    value = 'Very Wet'
  } else if (val > 73) {
    value = 'Wet'
  } else if (val > 53) {
    value = 'Moist'
  } else if (val > 27) {
      value = 'Dry'
  }
  return value
}

export const getUrlQueryParam = curry((location, key) => {
  const searchParams = new URLSearchParams(location.search)
  return searchParams.get(key)
})

export const ACCUWEATHER_STATION_TYPES = [
  'WS-2902-AW',
  'WS-2000-AW',
  'WS-5000-AW'
]
export const isAccuweatherDevice = d => {
  if (!d) return false
  return ACCUWEATHER_STATION_TYPES.includes(d.stationType)
}
export const accuweatherUrl = location => {
  const refs = ACCUWEATHER_STATION_TYPES
  const ref = getUrlQueryParam(location, 'ref')
  return (ref && refs.includes(ref)) ? ref : false
}

export function isOauth (location) {
  const client_id = getUrlQueryParam(location, 'client_id')
  return (client_id && contains(client_id, keys(OAUTH_CONFIG)))
}
function appendToPath (pth) {
  return pipe(
    flip(append)(pth),
    path
  )
}
export const isRole = curry(function (role, user) {
  const roles = path(['info', 'roles'], user)
  return roles && contains(role, roles)
})
export const isAdmin = function (user) {
  if (user.adminImpersonation) {
    const roles = path(['roles'], user.adminImpersonation)
    return roles && contains('admin', roles)
  } else {
    return isRole('admin', user)
  }
}
export const deviceHasEnhancedCam = currentDevice => {
  return path(['info', 'enhancedCam'], currentDevice) || path(['webcam', 'enhanced'], currentDevice)
}
export const getDeviceIdFromUrl = () => {
  const matches = (isCordova() ? window.location.hash : window.location.pathname).replace(/\?.*$/, '').match(/\/dashboard\/([^\/]+)/)
  return matches && matches[1]
}
export const showV3 = user => {
  return isLoggedIn(user) || getDeviceIdFromUrl()
}
export const hasSocial = user => {
  return isAdmin(user) || isRole('social', user)
}
export const canAccessOldData = user => {
  return isAdmin(user) || isRole('dev', user)
}
export const yearsUserCanAccess = user => {
  if (user.plus) {
    return 3
  }
  if (canAccessOldData(user)) {
    return 2
  }
  return 1
}
export const getUserSetting = appendToPath(['info', 'settings'])
export const getDeviceSetting = appendToPath(['settings'])
export function getDeviceLabel (dev) {
  var label = ''
  if (dev && dev.info) {
    if (dev.info.name) {
      label = dev.info.name
    }
    if (dev.info.coords && dev.info.coords.location) {
      label += ', ' + dev.info.coords.location
      if (dev.info.coords.address_components) {
        const state = dev.info.coords.address_components.find(c => c.types.includes('administrative_area_level_1'))
        if (state) {
          label += ', ' + state.short_name
        }
      }
    }
  }
  return label
}

// export const isFav = curry((user, d) => {
//   if (!isLoggedIn(user)) {
//     return false
//   }
//   const favs = getUserSetting('favs')(user)
//   const favKeys = Object.keys(favs || {})
//   if (!favs || favKeys.length === 0) {
//     return false
//   }
//   const deviceIds = pluck('_id', Object.values(favs))
//   if (d._id) {
//     return deviceIds.includes(d._id)
//   }
//   return favKeys.includes(path(['macAddress'], d))
// })
export const isFav = curry((social, d) => {
  const { favs } = social
  if (!favs) {
    return false
  }
  if (d.macAddress) {
    return favs.find(f => f.to.macAddress === d.macAddress)
  }
  return favs.find(f => f.to._id === d._id)
})

export const isUserPhoneConfirmed = allPass([path(['info', 'phone']), pipe(path(['info', 'phoneConfirmKey']), not)])
// returns theme slug for this user
export const getTheme = user => {
  const themeSetting = getUserSetting('theme')(user) || {}
  let ret = themeSetting.theme || 'light'
  if (ret === 'auto' && themeSetting.location) {
    const now = Date.now()
    const rise = astro.solar.rise(now, themeSetting.location.location.geo.coordinates[1], themeSetting.location.location.geo.coordinates[0])
    const set = astro.solar.set(now, themeSetting.location.location.geo.coordinates[1], themeSetting.location.location.geo.coordinates[0])
    ret = 'dark'
    if (now > rise && now < set) {
      ret = 'light'
    }
  }
  return ret
}

export const getUserUnitI = curry(function (key, user) {
  const theKey = /rainin/.test(key) ? 'hourlyrainin' : key
  const param = getMasterUnitParam(theKey)
  let userSetting = getUserSetting(param)(user) || {}
  // logged out user who used setUnits to choose metric
  // or on embed use owner's settings
  if (user.userUnits && !isLoggedIn(user)) {
    const metricSettings = {
      tempf: { unit: 1 },
      baromrelin: { unit: 1 },
      windspeedmph: { unit: 2 },
      hourlyrainin: { unit: 1 },
      metric: { unit: 1 }
    }
    if (typeof user.userUnits === 'object') {
      userSetting = path([param], user.userUnits) || {}
    } else if (user.userUnits === 'metric') {
      userSetting = path([param], metricSettings) || {}
    }
  } else if (['lightning_distance', 'altitudeft', 'airdensitylbft3', 'evapratelbft3hr'].includes(param)) {
    userSetting = getUserSetting('metric')(user) || {}
  }
  return isSomething(userSetting.unit) ? userSetting.unit : 0
})

export const getUnitArr = function (param) {
  const arr = path([param, 'units'], ambient.DATA_SPEC)
  if (arr) {
    return isArrayLike(arr) ? arr : getUnitArr(arr)
  }
  return false
}

export const getMasterUnitParam = function (param) {
  const arrOrStr = path([param, 'units'], ambient.DATA_SPEC)
  if (arrOrStr) {
    return isArrayLike(arrOrStr) ? param : arrOrStr
  } else if (contains(param, ['dewPoint', 'feelsLike'])) {
    return 'tempf'
  } else if (param === 'hour24') {
    return 'hour24'
  } else if (param === 'metric') {
    return 'metric'
  }
  return false
}

export const convertUnitForUser = curry(function (user, param, val) {
  const unitParam = getMasterUnitParam(param)
  const userUnitI = getUserUnitI(param, user)
  if (unitParam) {
    return parseFloat(convertUnit(unitParam, userUnitI, val))
  } else if (/humidity/.test(param) || param === 'uv' || param === 'pm25') {
    return parseInt(val, 10)
  } else if (param === 'pm25_24h' && val) {
    return parseFloat(parseFloat(val).toFixed(1))
  }
  return val
})

export const convertUnitForUserOnRow = curry(function (user, param) {
  return over(lensProp(param), convertUnitForUser(user, param))
})

export const getSuffForUser = curry(function (user, param) {
  const unitParam = getMasterUnitParam(param)
  const userUnitI = getUserUnitI(param, user)
  const origSuff = getSuff(unitParam || param)
  if (userUnitI > 0) {
    return origSuff.replace(ambient.DATA_SPEC[unitParam].units[0], ambient.DATA_SPEC[unitParam].units[userUnitI])
  }
  return origSuff || ''
})

export const timeFormatForUser = user => getUserUnitI('hour24', user) === 1 ? 'HH:mm' : 'h:mm a'

export function getTheDevice (device) {
  const { dashboardDevice, devices, deviceI, fetchedDevices } = device
  // v2
  if (dashboardDevice) {
    return deviceIsMine(device, dashboardDevice._id) ? find(propEq('_id', dashboardDevice._id), devices) : dashboardDevice
  }
  // public facing
  if (fetchedDevices[0]) {
    return fetchedDevices[0]
  }
  // v1
  if (devices && devices.length > 0) {
    return devices[deviceI] || devices[0]
  }
}

export function getDeviceCoords (device) {
  return path(['info', 'coords', 'coords'], getTheDevice(device)) // device has lat lon
}

export function getLabelForDevice (prop, theDevice) {
  // mimic the structure of the device redux obj
  return getLabel(prop, theDevice ? { fetchedDevices: [theDevice] } : null)
}
export function getLabel (prop, device) {
  if (prop === 'lightning') {
    return 'Lightning'
  }
  if (device && (device.devices || device.fetchedDevices)) {
    const theDevice = getTheDevice(device)
    return ambient.customParamLabel(prop, theDevice)
  }
  if (ambient.DATA_SPEC[prop]) {
    return ambient.DATA_SPEC[prop].label
  }
  if (prop === 'feelsLike') {
    return 'Feels Like'
  }
  if (prop === 'dewPoint') {
    return 'Dew Point'
  }
  if (prop === 'rain') {
    return 'Rainfall'
  }
  if (prop === 'rainTotal') {
    return 'Rain Total'
  }
  if (prop === 'notReporting') {
    return 'Device not reporting for 20 minutes'
  }
}
export const FIELD_PRIORITY = [
  'tempf',
  'feelsLike',
  'dewPoint',
  'windspeedmph',
  'windgustmph',
  'maxdailygust',
  'winddir',
  'rain',
  'baromrelin',
  'humidity',
  'uv',
  'solarradiation',
  'solarradday',
  'lightning',
  'tempinf',
  'pm25_in',
  'pm25_in_24h',
  'humidityin',
  'pm25',
  'pm25_24h',
  'sunMoon',
  'co2_in',
  'co2_in_24h',
  'co2_in_aqin',
  'pm25_in_aqin',
  'pm25_in_24h_aqin',
  'pm10_in_aqin',
  'pm10_in_24h_aqin',
  'pm_in_temp_aqin',
  'pm_in_humidity_aqin',
  'temp1f',
  'humidity1',
  'soiltemp1',
  'soilhum1',
  'temp2f',
  'humidity2',
  'soiltemp2',
  'soilhum2',
  'temp3f',
  'humidity3',
  'soiltemp3',
  'soilhum3',
  'temp4f',
  'humidity4',
  'soiltemp4',
  'soilhum4',
  'temp5f',
  'humidity5',
  'soiltemp5',
  'soilhum5',
  'temp6f',
  'humidity6',
  'soiltemp6',
  'soilhum6',
  'temp7f',
  'humidity7',
  'soiltemp7',
  'soilhum7',
  'temp8f',
  'humidity8',
  'soiltemp8',
  'soilhum8',
  'temp9f',
  'humidity9',
  'soiltemp9',
  'soilhum9',
  'temp10f',
  'humidity10',
  'soiltemp10',
  'soilhum10',
  'soilhum11',
  'soiltemp11',
  'soilhum12',
  'soiltemp12',
  'soilhum13',
  'soiltemp13',
  'soilhum14',
  'soiltemp14',
  'soilhum15',
  'soiltemp15',
  'soilhum16',
  'soiltemp16',
  'soiltens1',
  'soiltens2',
  'soiltens3',
  'soiltens4',
  'leafwetness1',
  'leafwetness2',
  'leafwetness3',
  'leafwetness4',
  'leafwetness5',
  'leafwetness6',
  'leafwetness7',
  'leafwetness8',
  'leafwetsum',
  'leafwetday',
  'gdd',
  'etos',
  'etrs',
  'wbgtf',
  'heatindexf',
  'pig',
  'dischargeft3s',
  'gaugeheightft',
  'watermeasph',
  'waternitratesmgl'
]
export const orderFields = sort((a, b) => {
  const aValue = /rain/.test(a) ? 'rain' : a
  const bValue = /rain/.test(b) ? 'rain' : b
  const aIndex = FIELD_PRIORITY.indexOf(aValue)
  const bIndex = FIELD_PRIORITY.indexOf(bValue)
  if (bIndex === aIndex) return 0
  if (aIndex < 0) return 1
  if (bIndex < 0) return -1
  return aIndex - bIndex
})
// give this an array of data and it will
// return the unique display keys
export const getDisplayKeys = pipe(
  map(keys),
  flatten,
  uniq,
  filter(flip(contains)(displayPropKeys)),
  orderFields
)
export const getDisplayKeysFromState = curry(function (dataKeys, state) {
  return pipe(
    pick(dataKeys),
    values,
    flatten,
    getDisplayKeys
  )(state)
})
export function widgetWhat (what, param, theDevice) {
  return path(['settings', param, what], theDevice)
}
export function widgetExpanded (param, theDevice) {
  return widgetWhat('expanded', param, theDevice)
}
export function widgetPinned (param, theDevice) {
  return widgetWhat('pinned', param, theDevice)
}
export function dataPending (dataKey, device) {
  return contains(dataKey, path(['fetchDeviceDataKeysPending'], device))
}
export const dataHas = curry(function (data, paths) {
  return pipe(
    groupWith(F),
    all((pth) => {
      return path(pth, data) || path(pth, data) === 0
    })
  )(isArrayLike(paths) ? paths : [paths])
})
export const dataHasAny = curry(function (data, fields) {
  return fcontainsAny(keys(data), fields)
})
// give an array of data, it'll tell you if
// any of them have this param
export const rowsHave = curry((rows, paths) => {
  return rows.filter(data => dataHas(data, paths)).length > 0
})
export const formatDate = curry(function (mode, d) {
  var format = 'MMM D h:mma'
  if (mode) {
    const res = fetchStartEndRes(mode).res
    d = roundDownMins(res, d)
    if (contains(mode, ['yearly'])) {
      format = 'MMM D'
    }
  }
  return fmtDate(format, d)
})
export const fmtDate = curry(function (format, d) {
  const dte = moment(d, 'x')
  return dte.format(format)
})
export const fmtzDate = curry(function (format, d) {
  const dte = moment(d)
  return dte.format(format)
})
export const sortByDateDesc = function (format) {
  return sort((arr, arr2) => {
    const date = arr[0]
    const date2 = arr2[0]
    return moment(date, format).unix() < moment(date2, format).unix() ? 1 : -1
  })
}
export const roundDownMins = curry(function (mins, d) {
  var amt = 1000 * 60 * mins
  return Math.floor(d / amt) * amt
})
export const removeDoc = function (id) {
  return filter(pipe(propEq('_id', id), not))
}
export function getInc (mode) {
  const hour = 1000 * 60 * 60
  var inc = hour * 3
  switch (mode) {
    case 'daily':
      inc = hour * 24
      break
    case 'weekly':
      inc = hour * 24 * 7
      break
    case 'monthly':
      inc = hour * 24 * 30
      break
    case 'yearly':
      inc = hour * 24 * 365
      break
    default:
      break
  }
  return inc
}
export function fetchStartEndRes (mode, start, page = 1) {
  // hd
  const hour = 1000 * 60 * 60
  var inc = hour * 3
  var res = 1
  var specialStart = false
  switch (mode) {
    case 'daily':
      inc = hour * 24
      res = 5
      specialStart = moment(start).startOf('day').valueOf()
      break
    case 'weekly':
      inc = hour * 24 * 7
      res = 30
      break
    case 'monthly':
      inc = hour * 24 * 30
      res = 240
      break
    case 'yearly':
      inc = hour * 24 * 365
      res = 1440
      break
  }
  return {
    start: specialStart || (start - inc * page),
    end: start - (page - 1) * inc,
    res: res
  }
}
export function getGrossRes (mode) {
  var res = 30 // daily
  switch (mode) {
    case 'weekly':
      res = 240
      break
    case 'monthly':
      res = 1440
      break
    case 'yearly':
      res = 1440
      break
  }
  return res
}
export const mockData = {
  stationtype: '5100',
  tempf: 98.6,
  humidity: 1,
  humidity1: 2,
  humidity3: 4,
  humidity4: 5,
  humidity5: 6,
  humidity6: 7,
  humidity7: 8,
  windspdmph_avg2m: 23,
  windspdmph_avg10m: 23,
  winddir_avg2m: 23,
  windspeedmph: 28.8,
  winddir: 280,
  windgustmph: 299,
  humidity8: 9,
  soilhum10: 99,
  tempinf: 49.0,
  temp2f: 50.0,
  temp3f: 51.0,
  temp4f: 52.0,
  temp6f: 54.0,
  temp7f: 55.0,
  temp8f: 56.0,
  soilhum9: 99,
  soiltemp9: 57.0,
  soiltemp10: 58.0,
  weeklyrainin: 4.4,
  monthlyrainin: 6.6,
  yearlyrainin: 8.8,
  totalrainin: 20.0,
  eventrainin: 1.3,
  relay1: 0,
  relay3: 0,
  relay4: 1,
  relay5: 0,
  relay6: 0,
  relay7: 1,
  relay8: 1,
  relay9: 0,
  relay10: 0,
  leak1: 0,
  leak3: 1,
  leafwetness1: 10,
  leafwetness2: 20,
  leafwetness5: 50,
  leafwetness8: 80,
  batleak1: 1,
  batleak2: 1,
  batleak3: 0,
  batleak4: 0,
  co2: 1001,
  co2_in: 100,
  uv: 2,
  maxdailygust: 99,
  solarradiation: 7,
  battout: 0,
  battin: 0,
  batt1: 1,
  battr5: 1,
  batt10: 0,
  battr10: 0,
  pm25: 33,
  pm25_24h: 15.8,
  pm25_in: 17,
  pm25_in_24h: 135,
  batt_25: 1,
  lightning_time: 1586837884000,
  lightning_day: 12,
  lightning_distance: 12.43,
  lightning_hour: 1,
  aqi_pm25_24h_aqin: 50,
  aqi_pm25_aqin: 3,
  pm10_in_24h_aqin: 999,
  pm10_in_aqin: 1,
  pm25_in_24h_aqin: 2,
  pm25_in_aqin: 0.8,
  pm_in_humidity_aqin: 43,
  // co2_in_24h_aqin: 585,
  // co2_in_aqin: 2400,
  pm_in_temp_aqin: 75,
  soiltens1: 10,
  soiltemp1: 55, // Soil Tension only appears if both tension and temp are present
  leafwet1x: 1,
  gdd: 42,
  etos: 0.6,
  etrs: 0.7,
  wbgtf: 99.3,
  heatindexf: 97.9,
  evapratelbft3hr: 43.3,
  twlwm2: 43.3,
  pig: 5,
  densityaltitudeft: 43.3,
  crosswindmph: 43.3,
  headwindmph: 43.3,
  ahlu1: 45.3,
  ahlu2: 46.3,
  ahlu3: 43.3,
  thinrc: 43.3,
  thiyousef: 43.3,
  humidityratiogplb: 43.3,
  relativeairdensity: 43.3,
  airdensitylbft3: 24.8,
  altitudeft: 43.3,
  deltaf: 43.3,
  airflowcfm: 43.3,
  windchillf: 43.3,
  hl: {
    solarradiation: {
      h: 100
    },
    pm25_in_aqin: {
      h: 100
    },
    pm10_in_aqin: {
      h: 45
    },
    pm25: {
      h: 100
    },
    pm25_in: {
      h: 123
    },
    leafwetsum: {
      s: 123
    }
  }
}
export const WIDGET_CONFIG = ambient.WIDGET_CONFIG
// we need a special function for the forecast widget sharing
// WIDGET_CONFIG.forecast.share = {
//   paths: [
//     ['currentDevice', 'info', 'coords', 'coords'],
//     ['device', 'forecastCache', function (props) {
//       console.log(props)
//       const coords = path(['currentDevice', 'info', 'coords', 'coords'], props)
//       const coordStr = coordString(coords.lat, coords.lon)
//       const obj = {}
//       obj[coordStr] = pick(['daily', 'currently', 'hourly'], path(['device', 'forecastCache', coordStr], props))
//       return obj
//     }]
//   ],
//   component: 'ForecastWidget'
// }


export const PRIVATE_PARAMS = ambient.PRIVATE_PARAMS
export const getWidgetTitle = curry((currentDevice, type) => {
  return ambient.getWidgetTitle(currentDevice, type)
})
export function getSuff (type) {
  if (ambient.DATA_SPEC[type] && ambient.DATA_SPEC[type].suff) {
    return ambient.DATA_SPEC[type].suff
  } else if (type === 'feelsLike' || type === 'dewPoint') {
    return '°'
  }
}
export const currentDeviceHasSummaries = (device) => {
  return true
}

export const getDateTz = curry((momentTz, device, d) => {
  return getDateTzForDevice(momentTz, getTheDevice(device), d)
})
export const getDateTzForDevice = curry((momentTz, theDevice, d) => {
  return momentTz.tz(d, path(['tz', 'name'], theDevice))
})
// summary dates can be a minute or two off, they should always start at the beginning of the day
export const getSummaryDocDateGetDate = curry((getDate, d) => {
  return getDate(d).add(5, 'm').startOf('day')
})
// summary dates can be a minute or two off, they should always start at the beginning of the day
export const getSummaryDocDate = curry((momentTz, device, d) => {
  return getSummaryDocDateGetDate(getDateTz(momentTz, device), d)
})
export const isWindDir = (type) => {
  return /wind/.test(type) && /dir/.test(type)
}
export const reduceSummaries = (docs) => {
  return pipe(
    getDisplayKeys,
    map(k => {
      const cleanedDocs = map(d => {
        if (typeof d.dateutc === 'string') {
          d.dateutc = parseInt(d.dateutc, 10)
        }
        return d
      }, docs)
      return [k, reduceSummariesForType(k)(cleanedDocs)]
    }),
    filter(arr => isWindDir(arr[0]) || arr[1].c > 0),
    fromPairs
  )(docs)
}

export const reduceSummariesForType = (type) => {
  if (/wind/.test(type) && /dir/.test(type)) {
    return pipe(reduce((acc, curr) => {
      const typeHl = curr[type]
      if (!typeHl) return acc
      for (let i in acc) {
        if (typeof typeHl === 'object') {
          if (typeHl[i]) {
            acc[i] += typeHl[i]
          }
        } else {
          ++acc[typeHl]
        }
      }
      return acc
    }, {
      N: 0,
      NNE: 0,
      NE: 0,
      ENE: 0,
      E: 0,
      ESE: 0,
      SE: 0,
      SSE: 0,
      S: 0,
      SSW: 0,
      SW: 0,
      WSW: 0,
      W: 0,
      WNW: 0,
      NW: 0,
      NNW: 0
    }), clone)
  }
  return pipe(
    reduce((acc, curr) => {
      const typeHl = curr[type]
      if (!typeHl) return acc
      if (typeHl.h > acc.h) {
        acc.h = typeHl.h * 1
        acc.ht = typeHl.ht * 1
      }
      if (typeHl.l < acc.l) {
        acc.l = typeHl.l * 1
        acc.lt = typeHl.lt * 1
      }
      // "high sum" for dailyrainin keeps track of "Rain Total"
      if (type === 'dailyrainin') {
        acc.hs += typeHl.h * 1
      }
      // handle custom averages
      if (isSomething(typeHl.avg)) {
        acc.avg = typeHl.avg * 1
      } else {
        acc.s += typeHl.s * 1
      }
      acc.c += typeHl.c * 1
      return acc
    }, {
      h: -1000000,
      l: 1000000,
      s: 0,
      c: 0,
      hs: 0
    }),
    clone
  )
}

export const getHlAvg = curry((doc, type) => {
  const hl = doc[type]
  if (!hl) return

  if (isWindDir(type)) {
    // archives have dominant wind direction alread
    if (typeof hl === 'string') {
      return hl

      // ongoing hl for the day
    } else {
      let dominantDirection
      let highestCount = 0
      for (let direction in hl) {
        let val = hl[direction]
        if (val > highestCount) {
          dominantDirection = direction
          highestCount = val
        }
      }
      return dominantDirection
    }
  } else {
    let avg = isSomething(hl.avg) ? hl.avg : hl.s / hl.c
    const avgFormat = path([type, 'avgFormat'], ambient.DATA_SPEC)
    if (avgFormat) {
      avg = avgFormat(avg)
    } else if (/hum/.test(type)) {
      avg = avg.toFixed(1)
    } 
    return avg
  }
})

export const padData = (res, finalData) => {
  return reduce((acc, curr) => {
    const lastData = last(acc)
    const resMin = res * 1000 * 60
    if (lastData) {
      const roundedLast = ambient.roundDownMins(res, lastData.dateutc)
      const roundedCurr = ambient.roundDownMins(res, curr.dateutc)
      if (roundedLast - roundedCurr > resMin) {
        for (let d = roundedLast - resMin; d > roundedCurr; d -= resMin) {
          acc.push({
            dateutc: d
          })
        }
      }
    }
    acc.push(curr)
    return acc
  }, [], finalData)
}

export const getAstroValue = curry((mTz, currentDevice, fn, t) => {
  const theTime = typeof t === 'object' ? t.valueOf() : t
  const lat = path(['lat'], currentDevice) || path(['info', 'coords', 'coords', 'lat'], currentDevice)
  const lon = path(['lon'], currentDevice) || path(['info', 'coords', 'coords', 'lon'], currentDevice)
  const tz = path(['tz', 'name'], currentDevice) || path(['tz'], currentDevice)
  return mTz.tz(parseInt(fn(theTime, lat, lon), 10), tz)
})

export const generalModalStyle = {
  content: {
    width: 500,
    left: '50%',
    marginLeft: -250,
    height: 'auto',
    bottom: null,
    borderRadius: 2
  }
}

export const containsOldDate = dates => {
  if (!dates) return
  return dates.filter(d => Date.now() - d.valueOf() >= 1000 * 60 * 60 * 24 * 30).length > 0
}

export const summaryDocsForDates = curry((mTz, device, dates, allDocs) => {
  const dayFmt = 'YYYY-MM-DD'
  return pipe(
    // if there are more than one, take the first
    groupBy(doc => {
      const docDay = getSummaryDocDate(mTz, device, doc.dateutc)
      return docDay.format(dayFmt)
    }),
    values,
    map(nth(0)),
    filter(doc => {
      const docDay = getSummaryDocDate(mTz, device, doc.dateutc)
      if (Array.isArray(dates)) {
        return dates[0].startOf('day').valueOf() <= docDay.valueOf() && dates[1].valueOf() >= docDay.valueOf()
      } else {
        return dates.format(dayFmt) === docDay.format(dayFmt)
      }
    })
    // sort((a, b) => moment(b.dateutc).valueOf() - moment(a.dateutc).valueOf())
  )(allDocs)
})

export function getDominantWindDir (doc) {
  if (typeof doc === 'string') return windDirFromLabel(doc)
  let highestCount = 0
  let dominantDirection
  for (let direction in doc) {
    let val = doc[direction]
    if (val > highestCount) {
      dominantDirection = direction
      highestCount = val
    }
  }
  return windDirFromLabel(dominantDirection)
}

export function convertLatLon (latLon, isLat) {
  if (isLat) {
    return Math.abs(latLon) + (latLon >= 0 ? 'N' : 'S')
  } else {
    return Math.abs(latLon) + (latLon >= 0 ? 'E' : 'W')
  }
}

export const tooltipFormatDate = curry(function (device, user, mode, d) {
  var format = 'MMM D ' + timeFormatForUser(user)
  if (mode) {
    const res = fetchStartEndRes(mode).res
    d = roundDownMins(res, d)
    if (contains(mode, ['yearly'])) {
      format = 'MMM D, YYYY'
    }
  }
  return getDateTz(window.moment, device, d).format(format + ' z')
})

export const deviceIsMine = (device, deviceId) => {
  const { devices } = device
  if (devices && contains(deviceId, pluck('_id', devices))) {
    return true
  }
}
export const theDeviceIsMine = (device) => {
  const theDevice = getTheDevice(device)
  if (theDevice) {
    return deviceIsMine(device, theDevice._id)
  }
}
export const shouldGetDevice = (device, deviceId, howManyMinutesIsOk) => {
  const { deviceCache, getDeviceIdsPending, getDeviceFailures } = device
  // is my device - no
  if (deviceIsMine(device, deviceId)) {
    return false
  }
  // not public or we're already fetching it - no
  if (contains(deviceId, getDeviceFailures) || contains(deviceId, getDeviceIdsPending)) {
    return false
  }
  const theDevice = deviceCache[deviceId]
  // it's not in the cache - yes
  if (!theDevice) {
    return true
  }
  // it is in the cache and we dont care how old it is - no
  if (!howManyMinutesIsOk) {
    return false
  }
  // it is in the cache and we care how old it is - maybe....
  return Date.now() - theDevice.fetched > 1000 * 60 * howManyMinutesIsOk
}

export const removeDayDataCache = state => {
  const st8 = Object.assign({}, state)
  Object.keys(state)
    .filter(k => /DayData$/.test(k))
    .forEach(k => {
      st8.deviceDataAllDataKeys = removeFromArr(k, st8.deviceDataAllDataKeys)
      delete st8[k]
    })
  return st8
}

export const pathsChanged = curry((props, nextProps, arrayOfPaths) => {
  return find(p => {
    const pathArr = typeof p === 'string' ? [p] : p
    return path(pathArr, props) !== path(pathArr, nextProps)
  }, arrayOfPaths) || false
})

export const tempColor = temp => {
  const tempConfig = MAP_LEGENDS.temp
  const tempMin = parseInt(last(tempConfig.items).label)
  const tempMax = parseInt(tempConfig.items[0].label)
  if (temp < tempMin) {
    temp = tempMin
  } else if (temp > tempMax) {
    temp = tempMax
  }
  return tempConfig.items.find(item => {
    return parseInt(item.label) === Math.round(temp / 10) * 10
  }).color
}
export const tempColorAeris = temp => {
  const colors = ['#8600b9', '#7d00a9', '#740098', '#6b0088', '#630078', '#70007e', '#7d0085', '#8a008b', '#970092', '#b300a6', '#c100b3', '#d000c1', '#de00ce', '#ed00dc', '#ee16e3', '#f02dea', '#f243f1', '#f45af8', '#f081fb', '#eb91f7', '#e6a1f4', '#e1b1f0', '#dcc1ed', '#b59ad6', '#8e73bf', '#684da8', '#412691', '#2b1095', '#3c20af', '#4c30ca', '#5d40e4', '#6e51ff', '#7765ff', '#8079ff', '#898dff', '#92a1ff', '#80b2fe', '#66affd', '#4cabfd', '#32a8fc', '#18a5fc', '#1584f7', '#1263f3', '#0f42ef', '#0c20eb', '#0b23b8', '#0d468a', '#0f695c', '#118c2e', '#14b000', '#41b701', '#6ebe03', '#9bc604', '#c8cd06', '#f1c106', '#edad04', '#e99903', '#e58501', '#e17200', '#e46500', '#e85800', '#eb4c00', '#ef3f00', '#ec2800', '#e51e00', '#de1400', '#d70a00', '#d00000', '#bd0000', '#aa0000', '#970000', '#840000', '#84000e', '#96001c', '#a9002a', '#bb0038', '#ce0046', '#d70f56', '#e11e66', '#eb2d77', '#f53c87', '#ff5e9c', '#ff72a0', '#ff85a4', '#ff99a8', '#ffadad']
  const tempMin = -60
  const tempMax = 130
  const theRange = Math.abs(tempMin) + tempMax
  const colorInc = theRange / colors.length
  return colors[Math.floor((temp - tempMin) / colorInc)] || '#ffadad'
}

export const getDeviceSlug = theDevice => {
  return path(['public', 'slug'], theDevice) || path(['info', 'slug'], theDevice) || md5(md5(path(['macAddress'], theDevice)))
}
export const getDeviceSharePath = theDevice => {
  return `/dashboard/${getDeviceSlug(theDevice)}`
}
export const getDeviceSharePathOld = theDevice => {
  return `/devices/public/${getDeviceSlug(theDevice)}`
}
export const getDeviceShareLink = theDevice => {
  const base = isCordova() ? 'https://ambientweather.net' : window.location.protocol + '//' + window.location.host
  return `${base}${getDeviceSharePath(theDevice)}`
}
export const getDeviceEmbedLink = theDevice => {
  const base = isCordova() ? 'https://ambientweather.net' : window.location.protocol + '//' + window.location.host
  return `${base}${getDeviceSharePathOld(theDevice)}?embed=true`
}

export const camId2Slug = (camId = '') => {
  const macAddress = splitEvery(2, camId).join(':')
  return md5(md5(macAddress))
}

/**
 * Handles changes to device settings for a user's device or their fav
 * 
 * Yes this is ugly. The real solution is to move this logic into MinMaxBtns, however
 * it's also being used to re-open minmized widget icons in DeviceRealtimeDashboard, and those are not MinMaxBtns.
 * so you get this instead...
 */
export const handleWidgetChange = curry((userActions, user, device, currentDevice, deviceActions, change, key) => {
  const theKeys = keys(change)
  const getNewSettings = settings => {
    if (!settings) {
      settings = {}
    }
    // special logic for pin
    if (theKeys.length === 1 && theKeys[0] === 'pinned') {
      change.expanded = change.pinned
    }
    settings[key] = merge(settings[key] || {}, change)
    // special logic for map/webcam toggle
    if (['map', 'webcam'].includes(key) && change.expanded) {
      if (key === 'map') {
        settings.webcam = { expanded: false }
      } else {
        settings.map = { expanded: false }
      }
    }
    return settings
  }
  const favs = getUserSetting('favs')(user) || {}
  if (deviceIsMine(device, currentDevice._id)) {
    const settings = getNewSettings(currentDevice.settings)
    deviceActions.patch(currentDevice._id, { settings })

  // someone else's
  } else if (favs[currentDevice.macAddress]) {
    favs[currentDevice.macAddress].settings = getNewSettings(favs[currentDevice.macAddress].settings)
    userActions.updateSetting('favs', favs)
  }
  if (theKeys.includes('expanded') && change.expanded) {
    window.scrollTo(0, 0)
  }
})


export const cleanDeviceToStore = pick(['_id', 'info', 'macAddress'])

export const coordString = (lat, lon) => {
  return lat.toString() + lon.toString()
}

export const toggleDevicePublic = (shareDevice) => {
  if (!shareDevice.public) {
    shareDevice.public = {
      indoorPublic: false,
      slug: shareDevice.passkey ? md5(shareDevice.passkey) : md5(md5(shareDevice.macAddress))
    }
  } else {
    shareDevice.public = null
  }
  return Object.assign({}, shareDevice)
}

export const showDeviceOnMap = (d) => {
  if (!d.lastData) {
    return false
  }
  // dev will have older temps, so show them
  if (/tornado/.test(getUrl()) || /localhost/.test(getUrl())) {
    return true
  }
  const tempf = path(['lastData', 'tempf'], d)
  if (!isSomething(tempf) || (isSomething(tempf) && tempf >= 140)) {
    return false
  }
  return Date.now() - d.lastData.dateutc < 1000 * 60 * 60 * 3 // older than 3 hours
}

export const coordsAreDifferent = (coords1, coords2) => {
  if (!coords1 && !coords2) return
  if (coords1 && !coords2) return true
  if (!coords1 && coords2) return true
  if (Array.isArray(coords1) && Array.isArray(coords2)) {
    return !(coords1[0] === coords2[0] && coords1[1] === coords2[1])
  }
  return !(coords1.lat === coords2.lat && coords1.lon === coords2.lon)
}


export const AD_CONFIG = {
  awnst1: {
    tags: ['stations']
  },
  awnst2: {
    tags: ['stations']
  },
  awnso1: {
    tags: ['social']
  },
  awnso2: {
    tags: ['social']
  }
}

export const getMapLocationLabel = mapLocation => path(['label'], mapLocation) || path(['location'], mapLocation) || path(['address'], mapLocation)

// returns video url if it exists
export const hasVideo = (currentDevice) => {
  // used for dev
  if (isBillingDev() && path(['webcam', 'username'], currentDevice)) {
    console.log('using test video')
    return `https://test-images.ambientweather.net/videos/latest/${currentDevice.webcam.username}.mp4`
  }
  if (path(['webcam', 'video'], currentDevice) && !path(['webcam', 'error'], currentDevice)) {
    return currentDevice.webcam.video
  }
  return path(['info', 'video'], currentDevice)
}

// pulled from LocationAutocomplete
export const findMyLocation = async () => {
  const Geocode = getGeocode()
  const callIfCallable = (obj, pth) => typeof path(pth, obj) === 'function' ? path(pth, obj)() : path(pth, obj)
  const doGoogleRes = res => {
    if (res) {
      const lat = callIfCallable(res, ['geometry', 'location', 'lat'])
      const lon = callIfCallable(res, ['geometry', 'location', 'lng'])
      // mimic lastLocationSearch format
      return ({
        label: res.formatted_address,
        location: findLocationFromGoogleRes(res.address_components),
        coords: {
          lat,
          lon
        },
        geo: {
          type: 'Point',
          coordinates: [lon, lat]
        }
      })
    }
  }

  return new Promise((resolve, reject) => {
    try {
      navigator.geolocation.getCurrentPosition(position => {
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
          .then(res => {
            resolve(doGoogleRes(path(['results'], res)[0]))
          })
          .catch(err => {
            resolve(null)
          })
      }, err => {
        resolve(null)
      })
    } catch (err) {
      resolve(null)
    }
  })
}

export const deCamelCase = str => {
  const result = str.replace( /([A-Z])/g, " $1" )
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const getForecastParams = user => {
  const settings = getUserSetting('forecast')(user) || {}
  return settings.params || [
    'Cloud Cover',
    'Temperature',
    'Precipitation'
  ]
}

export const hookMaker = (fnName, fnOrPath) => {
  return () => {
    if (Array.isArray(fnOrPath)) {
      return useSelector(state => ({ [fnName]: path(fnOrPath, state) }), shallowEqual)
    } else {
      const dispatch = useDispatch()
      const boundAction = useCallback((...params) => dispatch(fnOrPath(...params)), [dispatch])
      return { [fnName]: boundAction }
    }
  }
}
