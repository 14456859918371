// Ambient Weather Functions
const R = require('ramda')
const { timeFormatForUser } = require('../../ambient')
const owise1 = require('./owise1')

const toInt = val => parseInt(val, 10)
const toFixed = R.curry((amt, thing) => {
  if (typeof thing.toFixed === 'function'){
    return thing.toFixed(amt)
  }
  return thing
})

const funcs = {
  POST_CHARACTER_LIMIT: 300,
  DATA_SPEC: {
    ID: {
      type: 'Int'
    },
    PASSKEY: {
      type: 'String'
    },
    dateutc: {
      type: 'String',
      label: 'Date'
    },
    winddir: {
      type: 'Int',
      label: 'Wind Direction',
      suff: '°'
    },
    windspeedmph: {
      type: 'Float',
      label: 'Wind Speed',
      suff: ' mph',
      units: ['mph', 'ft/sec', 'm/sec', 'km/hr', 'knots'],
      spokenUnits: ['miles per hour', 'feet per second', 'meters per second', 'kilometers per hour', 'knots']
    },
    windgustmph: {
      type: 'Float',
      label: 'Wind Gust',
      suff: ' mph',
      units: 'windspeedmph'
    },
    maxdailygust: {
      type: 'Float',
      label: 'Max Daily Gust',
      suff: ' mph',
      units: 'windspeedmph'
    },
    windgustdir: {
      type: 'Int',
      label: 'Wind Gust Direction',
      suff: '°'
    },
    windspdmph_avg2m: {
      type: 'Float',
      label: 'Avg Wind Speed (2 mins)',
      suff: ' mph',
      units: 'windspeedmph'
    },
    winddir_avg2m: {
      type: 'Int',
      label: 'Avg Wind Direction (2 mins)',
      suff: '°'
    },
    windspdmph_avg10m: {
      type: 'Float',
      label: 'Avg Wind Speed (10 mins)',
      suff: ' mph',
      units: 'windspeedmph'
    },
    winddir_avg10m: {
      type: 'Int',
      label: 'Avg Wind Direction (10 mins)',
      suff: '°'
    },
    humidity: {
      type: 'Int',
      label: 'Outdoor Humidity',
      suff: '%',
      spokenUnit: 'percent'
    },
    humidity1: {
      type: 'Int',
      label: 'Humidity 1',
      suff: '%',
      private: true,
      spokenUnit: 'percent'
    },
    humidity2: {
      type: 'Int',
      label: 'Humidity 2',
      suff: '%',
      private: true,
      spokenUnit: 'percent'
    },
    humidity3: {
      type: 'Int',
      label: 'Humidity 3',
      suff: '%',
      private: true,
      spokenUnit: 'percent'
    },
    humidity4: {
      type: 'Int',
      label: 'Humidity 4',
      private: true,
      suff: '%',
      spokenUnit: 'percent'
    },
    humidity5: {
      type: 'Int',
      label: 'Humidity 5',
      private: true,
      suff: '%',
      spokenUnit: 'percent'
    },
    humidity6: {
      type: 'Int',
      label: 'Humidity 6',
      suff: '%',
      private: true,
      spokenUnit: 'percent'
    },
    humidity7: {
      type: 'Int',
      label: 'Humidity 7',
      suff: '%',
      private: true,
      spokenUnit: 'percent'
    },
    humidity8: {
      type: 'Int',
      label: 'Humidity 8',
      suff: '%',
      private: true,
      spokenUnit: 'percent'
    },
    humidity9: {
      type: 'Int',
      label: 'Humidity 9',
      suff: '%',
      private: true,
      spokenUnit: 'percent'
    },
    humidity10: {
      type: 'Int',
      label: 'Humidity 10',
      suff: '%',
      private: true,
      spokenUnit: 'percent'
    },
    humidityin: {
      type: 'Int',
      label: 'Indoor Humidity',
      suff: '%',
      spokenUnit: 'percent',
      private: true
    },
    humiditykestrel: {
      type: 'Float',
      label: 'Relative Humidity',
      suff: '%',
      spokenUnit: 'percent',
      private: true
    },
    tempf: {
      type: 'Float',
      label: 'Outdoor Temperature',
      suff: '°F',
      units: ['°F', '°C'],
      spokenUnits: ['degrees', 'degrees']
    },
    tempfkestrel: {
      type: 'Float',
      label: 'Temperature',
      suff: '°F',
      units: 'tempf',
      spokenUnits: ['degrees', 'degrees'],
      private: true
    },
    temp1f: {
      type: 'Float',
      label: 'Temperature 1',
      suff: '°F',
      units: 'tempf',
      private: true
    },
    temp2f: {
      type: 'Float',
      label: 'Temperature 2',
      suff: '°F',
      units: 'tempf',
      private: true
    },
    temp3f: {
      type: 'Float',
      label: 'Temperature 3',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    temp4f: {
      type: 'Float',
      label: 'Temperature 4',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    temp5f: {
      type: 'Float',
      label: 'Temperature 5',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    temp6f: {
      type: 'Float',
      label: 'Temperature 6',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    temp7f: {
      type: 'Float',
      label: 'Temperature 7',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    temp8f: {
      type: 'Float',
      label: 'Temperature 8',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    temp9f: {
      type: 'Float',
      label: 'Temperature 9',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    temp10f: {
      type: 'Float',
      label: 'Temperature 10',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike1: {
      type: 'Float',
      label: 'Feels Like 1',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike2: {
      type: 'Float',
      label: 'Feels Like 2',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike3: {
      type: 'Float',
      label: 'Feels Like 3',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike4: {
      type: 'Float',
      label: 'Feels Like 4',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike5: {
      type: 'Float',
      label: 'Feels Like 5',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike6: {
      type: 'Float',
      label: 'Feels Like 6',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike7: {
      type: 'Float',
      label: 'Feels Like 7',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike8: {
      type: 'Float',
      label: 'Feels Like 8',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike9: {
      type: 'Float',
      label: 'Feels Like 9',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLike10: {
      type: 'Float',
      label: 'Feels Like 10',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    feelsLikein: {
      type: 'Float',
      label: 'Indoor Feels Like',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint1: {
      type: 'Float',
      label: 'Dew Point 1',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint2: {
      type: 'Float',
      label: 'Dew Point 2',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint3: {
      type: 'Float',
      label: 'Dew Point 3',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint4: {
      type: 'Float',
      label: 'Dew Point 4',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint5: {
      type: 'Float',
      label: 'Dew Point 5',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint6: {
      type: 'Float',
      label: 'Dew Point 6',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint7: {
      type: 'Float',
      label: 'Dew Point 7',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint8: {
      type: 'Float',
      label: 'Dew Point 8',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint9: {
      type: 'Float',
      label: 'Dew Point 9',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPoint10: {
      type: 'Float',
      label: 'Dew Point 10',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    dewPointin: {
      type: 'Float',
      label: 'Indoor Dew Point',
      suff: '°F',
      private: true,
      units: 'tempf'
    },
    soiltemp1: {
      type: 'Float',
      label: 'Soil Temperature 1',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp2: {
      type: 'Float',
      label: 'Soil Temperature 2',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp3: {
      type: 'Float',
      label: 'Soil Temperature 3',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp4: {
      type: 'Float',
      label: 'Soil Temperature 4',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp5: {
      type: 'Float',
      label: 'Soil Temperature 5',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp6: {
      type: 'Float',
      label: 'Soil Temperature 6',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp7: {
      type: 'Float',
      label: 'Soil Temperature 7',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp8: {
      type: 'Float',
      label: 'Soil Temperature 8',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp9: {
      type: 'Float',
      label: 'Soil Temperature 9',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp10: {
      type: 'Float',
      label: 'Soil Temperature 10',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp11: {
      type: 'Float',
      label: 'Soil Temperature 11',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp12: {
      type: 'Float',
      label: 'Soil Temperature 12',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp13: {
      type: 'Float',
      label: 'Soil Temperature 13',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp14: {
      type: 'Float',
      label: 'Soil Temperature 14',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp15: {
      type: 'Float',
      label: 'Soil Temperature 15',
      suff: '°F',
      units: 'tempf'
    },
    soiltemp16: {
      type: 'Float',
      label: 'Soil Temperature 16',
      suff: '°F',
      units: 'tempf'
    },
    soilhum1: {
      type: 'Int',
      label: 'Soil Moisture 1',
      suff: '%',
      units: ['%', ' '],
      spokenUnits: ['percent', '']
    },
    soilhum2: {
      type: 'Int',
      label: 'Soil Moisture 2',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum3: {
      type: 'Int',
      label: 'Soil Moisture 3',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum4: {
      type: 'Int',
      label: 'Soil Moisture 4',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum5: {
      type: 'Int',
      label: 'Soil Moisture 5',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum6: {
      type: 'Int',
      label: 'Soil Moisture 6',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum7: {
      type: 'Int',
      label: 'Soil Moisture 7',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum8: {
      type: 'Int',
      label: 'Soil Moisture 8',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum9: {
      type: 'Int',
      label: 'Soil Moisture 9',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum10: {
      type: 'Int',
      label: 'Soil Moisture 10',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum11: {
      type: 'Int',
      label: 'Soil Moisture 11',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum12: {
      type: 'Int',
      label: 'Soil Moisture 12',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum13: {
      type: 'Int',
      label: 'Soil Moisture 13',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum14: {
      type: 'Int',
      label: 'Soil Moisture 14',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum15: {
      type: 'Int',
      label: 'Soil Moisture 15',
      suff: '%',
      units: 'soilhum1'
    },
    soilhum16: {
      type: 'Int',
      label: 'Soil Moisture 16',
      suff: '%',
      units: 'soilhum1'
    },
    tempinf: {
      type: 'Float',
      label: 'Indoor Temperature',
      suff: '°F',
      units: 'tempf',
      private: true
    },
    hourlyrainin: {
      type: 'Float',
      label: 'Rain Rate',
      suff: ' in/hr',
      units: ['in/hr', 'mm/hr'],
      spokenUnits: ['inches per hour', 'millimeters per hour']
    },
    dailyrainin: {
      type: 'Float',
      label: 'Daily Rain',
      suff: ' in',
      units: ['in', 'mm'],
      spokenUnits: ['inches', 'millimeters']
    },
    weeklyrainin: {
      type: 'Float',
      label: 'Weekly Rain',
      suff: ' in',
      units: 'dailyrainin'
    },
    monthlyrainin: {
      type: 'Float',
      label: 'Monthly Rain',
      suff: ' in',
      units: 'dailyrainin'
    },
    yearlyrainin: {
      type: 'Float',
      label: 'Yearly Rain',
      suff: ' in',
      units: 'dailyrainin'
    },
    eventrainin: {
      type: 'Float',
      label: 'Event Rain',
      suff: ' in',
      units: 'dailyrainin'
    },
    totalrainin: {
      type: 'Float',
      label: 'Total Rain',
      suff: ' in',
      units: 'dailyrainin'
    },
    '24hourrainin': {
      type: 'Float',
      label: '24 Hour Rain',
      suff: ' in',
      units: 'dailyrainin'
    },
    baromrelin: {
      type: 'Float',
      label: 'Relative Pressure',
      suff: ' inHg',
      units: ['inHg', 'mmHg', 'hPa'],
      spokenUnits: ['inches', 'millimeters', 'hectopascals']
    },
    baromabsin: {
      type: 'Float',
      label: 'Absolute Pressure',
      suff: ' inHg',
      units: 'baromrelin'
    },
    uv: {
      type: 'Float',
      label: 'Ultra-Violet Radiation Index',
      suff: '',
      avgFormat: toFixed(1)
    },
    solarradiation: {
      type: 'Float',
      label: 'Solar Radiation',
      suff: ' W/m^2',
      units: ['W/m^2', 'lux'],
      spokenUnits: ['', 'lux']
    },
    co2: {
      type: 'Float',
      label: 'CO2',
      suff: ' ppm',
      units: 'parts per million'
    },
    relay1: {
      type: 'Bool',
      private: true,
      label: 'Relay 1'
    },
    relay2: {
      type: 'Bool',
      private: true,
      label: 'Relay 2'
    },
    relay3: {
      type: 'Bool',
      private: true,
      label: 'Relay 3'
    },
    relay4: {
      type: 'Bool',
      private: true,
      label: 'Relay 4'
    },
    relay5: {
      type: 'Bool',
      private: true,
      label: 'Relay 5'
    },
    relay6: {
      type: 'Bool',
      private: true,
      label: 'Relay 6'
    },
    relay7: {
      type: 'Bool',
      private: true,
      label: 'Relay 7'
    },
    relay8: {
      type: 'Bool',
      private: true,
      label: 'Relay 8'
    },
    relay9: {
      type: 'Bool',
      private: true,
      label: 'Relay 9'
    },
    relay10: {
      type: 'Bool',
      private: true,
      label: 'Relay 10'
    },
    pm25: {
      type: 'Float',
      suff: 'µg/m^3',
      label: 'PM2.5 Air Quality Sensor',
      avgFormat: toInt
    },
    pm25_24h: {
      type: 'Float',
      suff: 'µg/m^3',
      label: 'PM2.5 Air Quality Sensor, 24 hour running average',
      avgFormat: toFixed(1)
    },
    pm25_in: {
      type: 'Float',
      suff: 'µg/m^3',
      private: true,
      label: 'PM2.5 Air Quality Sensor, indoor',
      avgFormat: toFixed(1)
    },
    pm25_in_24h: {
      type: 'Float',
      suff: 'µg/m^3',
      private: true,
      label: 'PM2.5 Air Quality Sensor indoor, 24 hour running average',
      avgFormat: toFixed(1)
    },
    pm25_in_aqin: {
      type: 'Float',
      suff: 'µg/m^3',
      private: true,
      avgFormat: toInt,
      label: 'PM2.5 Air Quality Sensor indoor, AQIN sensor'
    },
    pm25_in_24h_aqin: {
      type: 'Float',
      suff: 'µg/m^3',
      private: true,
      avgFormat: toInt,
      label: 'PM2.5 Air Quality Sensor indoor, 24 hour running average, AQIN sensor'
    },
    pm10_in_aqin: {
      type: 'Float',
      suff: 'µg/m^3',
      private: true,
      avgFormat: toInt,
      label: 'PM10 Air Quality Sensor'
    },
    pm10_in_24h_aqin: {
      type: 'Float',
      suff: 'µg/m^3',
      private: true,
      avgFormat: toInt,
      label: 'PM10 Air Quality Sensor, 24 hour running average'
    },
    co2_in_aqin: {
      type: 'Int',
      label: 'Indoor CO2 from AQIN',
      suff: 'ppm',
      avgFormat: toInt,
      private: true
    },
    co2_in_24h_aqin: {
      type: 'Float',
      label: 'Indoor CO2 from AQIN, 24 hour running average',
      suff: 'ppm',
      avgFormat: toInt,
      private: true
    },
    pm_in_temp_aqin: {
      type: 'Float',
      suff: '°F',
      units: 'tempf',
      label: 'Indoor PM sensor temperature',
      private: true
    },
    pm_in_humidity_aqin: {
      type: 'Int',
      label: 'Indoor PM sensor humidity',
      suff: '%',
      spokenUnit: 'percent',
      private: true
    },
    aqi_pm25_aqin: {
      type: 'Int',
      label: 'AQI derived from PM25, AQIN sensor',
      private: true
    },
    aqi_pm25_24h_aqin: {
      type: 'Int',
      label: 'AQI derived from PM25 Indoor, 24 hour running average, AQIN sensor',
      private: true
    },
    aqi_pm10_aqin: {
      type: 'Int',
      label: 'AQI derived from PM10 Indoor, AQIN sensor',
      private: true
    },
    aqi_pm10_24h_aqin: {
      type: 'Int',
      label: 'AQI derived from PM10 Indoor, 24 hour running average, AQIN sensor',
      private: true
    },
    aqi_pm25: {
      type: 'Int',
      label: 'AQI derived from PM25'
    },
    aqi_pm25_24h: {
      type: 'Int',
      label: 'AQI derived from PM25, 24 hour running average'
    },
    aqi_pm25_in: {
      type: 'Int',
      private: true,
      label: 'AQI derived from PM25 IN'
    },
    aqi_pm25_in_24h: {
      type: 'Int',
      private: true,
      label: 'AQI derived from PM25 IN, 24 hour running average'
    },
    battout: {
      type: 'Bool',
      private: true,
      label: 'Outdoor Battery'
    },
    battin: {
      type: 'Bool',
      private: true,
      label: 'Indoor Battery'
    },
    batt1: {
      type: 'Bool',
      private: true,
      label: 'Sensor 1 Battery'
    },
    batt2: {
      type: 'Bool',
      private: true,
      label: 'Sensor 2 Battery'
    },
    batt3: {
      type: 'Bool',
      private: true,
      label: 'Sensor 3 Battery'
    },
    batt4: {
      type: 'Bool',
      private: true,
      label: 'Sensor 4 Battery'
    },
    batt5: {
      type: 'Bool',
      private: true,
      label: 'Sensor 5 Battery'
    },
    batt6: {
      type: 'Bool',
      private: true,
      label: 'Sensor 6 Battery'
    },
    batt7: {
      type: 'Bool',
      private: true,
      label: 'Sensor 7 Battery'
    },
    batt8: {
      type: 'Bool',
      private: true,
      label: 'Sensor 8 Battery'
    },
    batt9: {
      type: 'Bool',
      private: true,
      label: 'Sensor 9 Battery'
    },
    batt10: {
      type: 'Bool',
      private: true,
      label: 'Sensor 10 Battery'
    },
    battr1: {
      type: 'Bool',
      private: true,
      label: 'Relay 1 Battery'
    },
    battr2: {
      type: 'Bool',
      private: true,
      label: 'Relay 2 Battery'
    },
    battr3: {
      type: 'Bool',
      private: true,
      label: 'Relay 3 Battery'
    },
    battr4: {
      type: 'Bool',
      private: true,
      label: 'Relay 4 Battery'
    },
    battr5: {
      type: 'Bool',
      private: true,
      label: 'Relay 5 Battery'
    },
    battr6: {
      type: 'Bool',
      private: true,
      label: 'Relay 6 Battery'
    },
    battr7: {
      type: 'Bool',
      private: true,
      label: 'Relay 7 Battery'
    },
    battr8: {
      type: 'Bool',
      private: true,
      label: 'Relay 8 Battery'
    },
    battr9: {
      type: 'Bool',
      private: true,
      label: 'Relay 9 Battery'
    },
    battr10: {
      type: 'Bool',
      private: true,
      label: 'Relay 10 Battery'
    },
    batt_25: {
      type: 'Bool',
      private: true,
      label: 'PM2.5 Battery'
    },
    batt_25in: {
      type: 'Bool',
      private: true,
      label: 'PM2.5 Indoor Battery'
    },
    batleak1: {
      type: 'Bool',
      private: true,
      label: 'Leak Detection 1 Battery'
    },
    batleak2: {
      type: 'Bool',
      private: true,
      label: 'Leak Detection 2 Battery'
    },
    batleak3: {
      type: 'Bool',
      private: true,
      label: 'Leak Detection 3 Battery'
    },
    batleak4: {
      type: 'Bool',
      private: true,
      label: 'Leak Detection 4 Battery'
    },
    batt_lightning: {
      type: 'Bool',
      label: 'Lightning Detector Battery'
    },
    battsm1: {
      type: 'Bool',
      label: 'Soil Moisture 1 Battery'
    },
    battsm2: {
      type: 'Bool',
      label: 'Soil Moisture 2 Battery'
    },
    battsm3: {
      type: 'Bool',
      label: 'Soil Moisture 3 Battery'
    },
    battsm4: {
      type: 'Bool',
      label: 'Soil Moisture 4 Battery'
    },
    batt_co2: {
      type: 'Bool',
      label: 'CO2 battery'
    },
    batt_cellgateway: {
      type: 'Bool',
      label: 'Cellular Gateway battery'
    },
    battvolts: {
      type: 'Float',
      label: 'Battery Voltage',
      suff: 'V'
    },
    batt1volts: {
      type: 'Float',
      label: 'Battery Voltage 1',
      suff: 'V'
    },
    batt2volts: {
      type: 'Float',
      label: 'Battery Voltage 2',
      suff: 'V'
    },
    leak1: {
      type: 'Int',
      private: true,
      label: 'Leak Detection 1'
    },
    leak2: {
      type: 'Int',
      private: true,
      label: 'Leak Detection 2'
    },
    leak3: {
      type: 'Int',
      private: true,
      label: 'Leak Detection 3'
    },
    leak4: {
      type: 'Int',
      private: true,
      label: 'Leak Detection 4'
    },
    lightning_day: {
      type: 'Int',
      label: 'Lightning strikes per day',
      avgFormat: toFixed(1)
    },
    lightning_hour: {
      type: 'Int',
      label: 'Lightning strikes per hour',
      avgFormat: toFixed(1)
    },
    lightning_distance: {
      type: 'Float',
      label: 'Distance of last lightning strike',
      suff: 'mi',
      units: ['mi', 'km'],
      spokenUnits: ['miles', 'kilometers']
    },
    lightning_time: {
      type: 'Date',
      label: 'Last strike time'
    },
    co2_in: {
      private: true,
      type: 'Int',
      label: 'Indoor CO2 standalone'
    },
    co2_in_24h: {
      private: true,
      type: 'Int',
      label: 'Indoor CO2 standalone, 24 hour running average'
    },
    solarradday: {
      type: 'Float',
      label: 'Solar Radiation Sum',
      suff: ' J/m^2',
      simpleWidget: true
    },
    leafwet1x: {
      type: 'Bool',
      label: 'Leaf Wetness'
    },
    leafwetsum: {
      type: 'Int',
      label: 'Leaf Wetness Minutes',
      suff: 'min',
      units: ['minutes', 'hours']
    },
    leafwetness1: {
      type: 'Int',
      label: 'Leaf Wetness 1',
      suff: '%',
      simpleGraph: true
    },
    leafwetness2: {
      type: 'Int',
      label: 'Leaf Wetness 2',
      suff: '%',
      simpleGraph: true
    },
    leafwetness3: {
      type: 'Int',
      label: 'Leaf Wetness 3',
      suff: '%',
      simpleGraph: true
    },
    leafwetness4: {
      type: 'Int',
      label: 'Leaf Wetness 4',
      suff: '%',
      simpleGraph: true
    },
    leafwetness5: {
      type: 'Int',
      label: 'Leaf Wetness 5',
      suff: '%',
      simpleGraph: true
    },
    leafwetness6: {
      type: 'Int',
      label: 'Leaf Wetness 6',
      suff: '%',
      simpleGraph: true
    },
    leafwetness7: {
      type: 'Int',
      label: 'Leaf Wetness 7',
      suff: '%',
      simpleGraph: true
    },
    leafwetness8: {
      type: 'Int',
      label: 'Leaf Wetness 8',
      suff: '%',
      simpleGraph: true
    },
    soiltens1: {
      type: 'Float',
      label: 'Soil Tension 1',
      suff: 'cb',
      units: ['cb', 'kPa']
    },
    soiltens2: {
      type: 'Float',
      label: 'Soil Tension 2',
      suff: 'cb',
      units: 'soiltens1'
    },
    soiltens3: {
      type: 'Float',
      label: 'Soil Tension 3',
      suff: 'cb',
      units: 'soiltens1'
    },
    soiltens4: {
      type: 'Float',
      label: 'Soil Tension 4',
      suff: 'cb',
      units: 'soiltens1'
    },
    gdd: {
      type: 'Int',
      label: 'Growing Degree Days',
      suff: 'days',
      avgFormat: toFixed(1)
    },
    etos: {
      type: 'Float',
      label: 'Evapotranspiration short',
      suff: 'in/day',
      units: ['in/day', 'mm/day']
    },
    etrs: {
      type: 'Float',
      label: 'Evapotranspiration, tall',
      units: 'etos'
    },
    wbgtf: {
      type: 'Float',
      label: 'WBGT',
      suff: '°F',
      private: true,
      units: 'tempf',
      simpleGraph: true
    },
    wbgtflagzone: {
      type: 'String',
      label: 'Zone'
    },
    heatindexf: {
      type: 'Float',
      label: 'Heat Index',
      suff: '°F',
      private: true,
      units: 'tempf',
      simpleGraph: true
    },
    blackglobef: {
      type: 'Float',
      label: 'Black Globe',
      suff: '°F',
      private: true,
      units: 'tempf',
      simpleGraph: true
    },
    wetbulbf: {
      type: 'Float',
      label: 'Wet Bulb',
      suff: '°F',
      private: true,
      units: 'tempf',
      simpleGraph: true
    },
    nawbf: {
      type: 'Float',
      label: 'NAWB',
      suff: '°F',
      private: true,
      units: 'tempf',
      simpleGraph: true
    },
    pig: {
      type: 'Float',
      label: 'PIG',
      suff: '%',
      private: true,
      simpleGraph: true
    },
    dischargeft3s: {
      type: 'Float',
      label: 'Discharge Rate',
      suff: 'ft3/s',
      units: 'ft3s'
    },
    gaugeheightft: {
      type: 'Float',
      label: 'Gauge Height',
      suff: 'ft',
      units: 'ft'
    },
    watermeasph: {
      type: 'Float',
      label: 'Water pH',
      suff: 'pH',
      units: 'pH'
    },
    waternitratesmgl: {
      type: 'Float',
      label: 'Water Nitrates',
      suff: 'mg/l',
      units: 'mgl'
    },
    evapratelbft3hr: {
      type: 'Float',
      label: 'Evaporation Rate',
      suff: 'lb/ft^2/hr',
      private: true,
      simpleWidget: true,
      units: ['lb/ft^2/hr', 'kg/m^2/hr'],
      simpleGraph: true
    },
    twlwm2: {
      type: 'Float',
      label: 'Thermal Work Limit',
      suff: 'W/m^2',
      private: true,
      simpleWidget: true,
      simpleGraph: true
    },
    densityaltitudeft: {
      type: 'Float',
      label: 'Density Altitude',
      suff: 'ft',
      private: true,
      simpleWidget: true,
      simpleGraph: true,
      units: 'altitudeft'
    },
    crosswindmph: {
      type: 'Float',
      label: 'Crosswind/Headwind',
      suff: 'mph',
      private: true,
      simpleGraph: true,
      units: 'windspeedmph'
    },
    headwindmph: {
      type: 'Float',
      label: 'Crosswind/Headwind',
      suff: 'mph',
      private: true,
      simpleGraph: true,
      units: 'windspeedmph'
    },
    ahlu1: {
      type: 'Float',
      label: 'AHLU 1',
      suff: '',
      private: true,
      simpleWidget: true,
      simpleGraph: true
    },
    ahlu2: {
      type: 'Float',
      label: 'AHLU 2',
      suff: '',
      private: true,
      simpleWidget: true,
      simpleGraph: true
    },
    ahlu3: {
      type: 'Float',
      label: 'AHLU 3',
      suff: '',
      private: true,
      simpleWidget: true,
      simpleGraph: true
    },
    humidityratiogplb: {
      type: 'Int',
      label: 'Humidity Ratio',
      suff: 'gr/lb',
      private: true,
      units: ['gr/lb', 'gr/kg'],
      simpleWidget: true,
      simpleGraph: true
    },
    airdensitylbft3: {
      type: 'Float',
      label: 'Air Density',
      suff: 'lb/ft^3',
      private: true,
      simpleGraph: true,
      units: ['lb/ft^3', 'kg/m^3']
    },
    relativeairdensity: {
      type: 'Float',
      label: 'Relative Air Density',
      suff: '%',
      private: true,
      simpleGraph: true
    },
    altitudeft: {
      type: 'Float',
      label: 'Altitude',
      suff: 'ft',
      private: true,
      simpleWidget: true,
      simpleGraph: true,
      units: ['ft', 'm']
    },
    deltaf: {
      type: 'Float',
      label: 'Delta T',
      suff: '°F',
      private: true,
      units: 'tempf',
      simpleGraph: true,
      simpleWidget: true
    },
    airflowcfm: {
      type: 'Float',
      label: 'Volume Air Flow',
      suff: 'cfm',
      private: true,
      simpleWidget: true,
      units: ['cfm', 'm^3/min'],
      simpleGraph: true
    },
    windchillf: {
      type: 'Float',
      label: 'Wind Chill',
      suff: '°F',
      units: 'tempf',
      private: true,
      simpleWidget: true,
      simpleGraph: true
    },
    thinrc: {
      type: 'Float',
      label: 'Temperature Humidity Index (NRC)',
      suff: '',
      private: true,
      simpleWidget: true,
      simpleGraph: true
    },
    thiyousef: {
      type: 'Float',
      label: 'Temperature Humidity Index (Yousef)',
      suff: '',
      private: true,
      simpleWidget: true,
      simpleGraph: true
    },
    leafwetday: {
      type: 'Int',
      label: 'Leaf Wetness, Daily Sum',
      suff: 'min',
      simpleWidget: true,
      units: 'leafwetsum'
    }
  },
  getPropsByType: function (type) {
    return owise1.keysWithValue(type, funcs.DATA_SPEC)
  }
}
funcs.PRIVATE_PARAMS = R.pipe(
  R.toPairs,
  R.filter(function (arr) {
    return arr[1].private
  }),
  R.map(function (arr) {
    return arr[0]
  }),
  R.concat(['indoor', 'battery'])
)(funcs.DATA_SPEC)
funcs.isSomething = function (v) {
  return v || v === 0
} 
funcs.windDirLabel = function (val) {
  if (val < 11.25) {
    val = 'N'
  } else if (val < 33.75) {
    val = 'NNE'
  } else if (val < 56.25) {
    val = 'NE'
  } else if (val < 78.75) {
    val = 'ENE'
  } else if (val < 101.25) {
    val = 'E'
  } else if (val < 123.75) {
    val = 'ESE'
  } else if (val < 146.25) {
    val = 'SE'
  } else if (val < 168.75) {
    val = 'SSE'
  } else if (val < 191.25) {
    val = 'S'
  } else if (val < 213.75) {
    val = 'SSW'
  } else if (val < 236.25) {
    val = 'SW'
  } else if (val < 258.75) {
    val = 'WSW'
  } else if (val < 281.25) {
    val = 'W'
  } else if (val < 303.75) {
    val = 'WNW'
  } else if (val < 326.25) {
    val = 'NW'
  } else if (val < 348.75) {
    val = 'NNW'
  } else {
    val = 'N'
  }
  return val
}

funcs.roundDownMins = R.curry(function (mins, d) {
  var amt = 1000 * 60 * mins
  return Math.floor(d / amt) * amt
})
funcs.roundDataDateutc = R.curry(function (mins) {
  return R.map(R.over(R.lensProp('dateutc'), funcs.roundDownMins(mins)))
})
funcs.formatProp = function (prop, value) {
  if (prop === 'dateutc') {
    return funcs.roundDownMins(1, Date.parse(value))
  }
  if (!funcs.DATA_SPEC[prop]) return value

  if (prop === 'lightning_distance') {
    var v = parseFloat(value, 10)
    if (isNaN(v)) {
      return 0
    }
    return parseFloat((v * 0.62137).toFixed(2), 10)
  }

  var type = funcs.DATA_SPEC[prop].type
  var ret = value
  switch (type) {
    case 'Int':
      ret = parseInt(value, 10)
      break
    case 'Bool':
      ret = parseInt(value, 10)
      break
    case 'Float':
      ret = parseFloat(value, 10)
      break
    case 'Date':
      ret = parseInt(value, 10) * 1000
      break
  }
  return ret
}
funcs.formatProps = R.mapObjIndexed(R.flip(funcs.formatProp))
funcs.getDisplayProps = function () {
  return R.pipe(
    R.toPairs,
    R.filter(function (arr) {
      return R.prop('label', arr[1])
    }),
    R.fromPairs
  )(funcs.DATA_SPEC)
}
funcs.toCel = function (f) {
  return (5 / 9) * (f - 32)
}
funcs.toFahr = function (c) {
  return (c * (9 / 5)) + 32
}
funcs.getDeviceLabel = function (dev) {
  var label = 'my weather station'
  if (dev && dev.info) {
    if (dev.info.name) {
      label = dev.info.name
    }
    if (dev.info.location) {
      label += ' - ' + dev.info.location
    }
  }
  return label
}
funcs.conversions = {
  tempf: [
    R.pipe(
      R.identity,
      toFixed(1)
    ),
    R.pipe(
      funcs.toCel,
      toFixed(1)
    )
  ],
  baromrelin: [
    R.pipe(
      R.identity,
      toFixed(2)
    ),
    R.pipe(
      R.divide(R.__, 0.0393700791974),
      toFixed(1)
    ),
    R.pipe(
      R.multiply(33.86389),
      toFixed(1)
    )
  ],
  windspeedmph: [
    R.pipe(
      R.identity,
      toFixed(1)
    ),
    // ft/sec
    R.pipe(
      R.multiply(1.4666667136),
      toFixed(1)
    ),
    // m/sec
    R.pipe(
      R.multiply(0.44704),
      toFixed(1)
    ),
    // km/hr
    R.pipe(
      R.multiply(1.609344),
      toFixed(1)
    ),
    // knots
    R.pipe(
      R.multiply(0.86897624190816),
      toFixed(1)
    )
  ],
  hourlyrainin: [
    R.pipe(
      R.identity,
      toFixed(2)
    ),
    R.pipe(
      R.multiply(25.4),
      toFixed(1)
    )
  ],
  dailyrainin: [
    R.pipe(
      R.identity,
      toFixed(2)
    ),
    R.pipe(
      R.multiply(25.4),
      toFixed(1)
    )
  ],
  solarradiation: [
    R.pipe(
      R.identity,
      toFixed(1)
    ),
    R.pipe(
      R.multiply(126.7),
      Math.round
    )
  ],
  soilhum1: [
    R.identity,
    v => Math.floor(((v / 99) * 15) + 1)
  ],
  lightning_distance: [
    // miles
    toInt,
    // km
    R.pipe(
      R.multiply(1.609344),
      toFixed(1)
    )
  ],
  soiltens1: [
    R.pipe(
      R.identity,
      toFixed(0)
    ),
    R.pipe(
      R.identity,
      toFixed(0)
    )
  ],
  leafwetsum: [ // conversions
    // minutes
    R.pipe(
      R.identity,
      toFixed(0)
    ),
    // hours
    R.pipe(
      R.divide(R.__, 60),
      toFixed(1)
    )
  ],  
  etos: [
    //inches/day
    R.pipe(
      R.identity,
      toFixed(2)
    ),
    //mm/day
    R.pipe(
      R.multiply(25.4),
      toFixed(1)
    )
  ],
  ft3s: [
    R.pipe(
      R.identity,
      toFixed(1)
    )
  ],
  ft: [
    R.pipe(
      R.identity,
      toFixed(2)
    )
  ],
  pH: [
    R.pipe(
      R.identity,
      toFixed(2)
    )
  ],
  mgl: [
    R.pipe(
      R.identity,
      toFixed(2)
    )
  ],
  altitudeft: [
    R.identity,
    R.pipe(
      R.multiply(0.3048),
      toFixed(1)
    )
  ],
  airdensitylbft3: [
    R.identity,
    R.pipe(
      R.multiply(16.0185),
      toFixed(1)
    )
  ],
  evapratelbft3hr: [
    R.identity,
    R.pipe(
      R.multiply(16.0185),
      toFixed(1)
    )
  ],
  airflowcfm: [
    R.identity,
    R.pipe(
      R.multiply(0.028),
      toFixed(1)
    )
  ],
  humidityratiogplb: [
    R.identity,
    R.pipe(
      R.multiply(0.142857),
      toFixed(1)
    )
  ]
}
const conversionsInverse = {
  tempf: [
    R.identity,
    funcs.toFahr
  ],
  baromrelin: [
    R.identity,
    R.pipe(
      R.multiply(0.0393700791974)
    ),
    R.pipe(
      R.divide(R.__, 33.86389)
    )
  ],
  windspeedmph: [
    R.pipe(
      R.identity
    ),
    // ft/sec
    R.pipe(
      R.divide(R.__, 1.4666667136)
    ),
    // m/sec
    R.pipe(
      R.divide(R.__, 0.44704)
    ),
    // km/hr
    R.pipe(
      R.divide(R.__, 1.609344)
    ),
    // knots
    R.pipe(
      R.divide(R.__, 0.86897624190816)
    )
  ],
  hourlyrainin: [
    R.pipe(
      R.identity
    ),
    R.pipe(
      R.divide(R.__, 25.4)
    )
  ],
  dailyrainin: [
    R.pipe(
      R.identity
    ),
    R.pipe(
      R.divide(R.__, 25.4)
    )
  ],
  solarradiation: [
    R.pipe(
      R.identity
    ),
    R.pipe(
      R.divide(R.__, 126.7)
    )
  ],
  soilhum1: [
    R.identity,
    v => Math.floor((v - 1) / 15 * 99)
  ],
  lightning_distance: [
    // miles
    toInt,
    // km
    R.divide(R.__, 1.609344)
  ],
  soiltens1: [
    R.pipe(
      R.identity,
      toFixed(1)
    ),
    R.pipe(
      R.identity,
      toFixed(1)
    )
  ],
  leafwetsum: [ // inverse
    // minutes
    R.pipe(
      R.multiply(60),
      toFixed(0)
    ),
    // hours
    R.pipe(
      R.identity,
      toFixed(1)
    )
  ],
  etos: [
    // inches/day
    R.pipe(
      R.identity
    ),
    // mm/day
    R.pipe(
      R.divide(R.__, 25.4)
    )
  ],
  altitudeft: [
    R.identity,
    R.pipe(
      R.divide(R.__, 0.3048),
      toFixed(1)
    )
  ],
  airdensitylbft3: [
    R.identity,
    R.pipe(
      R.divide(R.__, 16.0185),
      toFixed(1)
    )
  ],
  evapratelbft3hr: [
    R.identity,
    R.pipe(
      R.divide(R.__, 16.0185),
      toFixed(1)
    )
  ],
  airflowcfm: [
    R.identity,
    R.pipe(
      R.divide(R.__, 0.028),
      toFixed(1)
    )
  ],
  humidityratiogplb: [
    R.identity,
    R.pipe(
      R.divide(R.__, 0.142857),
      toFixed(1)
    )
  ]
}
function appendToPath (pth) {
  return R.pipe(
    R.flip(R.append)(pth),
    R.path
  )
}
funcs.getUserSetting = appendToPath(['settings'])
funcs.convertUnit = function (key, i, val) {
  if (funcs.isSomething(val)) {
    return funcs.conversions[key][i](val)
  }
}
funcs.convertUnitInverse = function (key, i, val) {
  if (funcs.isSomething(val)) {
    return conversionsInverse[key][i](val)
  }
}
funcs.getMasterUnitParam = function (param) {
  const arrOrStr = R.path([param, 'units'], funcs.DATA_SPEC)
  if (arrOrStr) {
    return Array.isArray(arrOrStr) ? param : arrOrStr
  } else if (R.contains(param, ['dewPoint', 'feelsLike'])) {
    return 'tempf'
  }
  return false
}
funcs.getUserUnitI = R.curry(function (key, user) {
  let theKey = key
  if (key === 'dailyrainin') {
    theKey = 'hourlyrainin'
  }
  const userSetting = funcs.getUserSetting(funcs.getMasterUnitParam(theKey))(user) || {}
  return funcs.isSomething(userSetting.unit) ? userSetting.unit : 0
})

funcs.getUnitArr = function (param) {
  const arr = R.path([param, 'units'], funcs.DATA_SPEC)
  if (arr) {
    return funcs.isArrayLike(arr) ? arr : funcs.getUnitArr(arr)
  }
  return false
}
funcs.convertUnitForUser = R.curry(function (user, param, val) {
  const unitParam = funcs.getMasterUnitParam(param)
  const userUnitI = funcs.getUserUnitI(param, user)
  if (unitParam) {
    return parseFloat(funcs.convertUnit(unitParam, userUnitI, val))
  }
  return val
})
funcs.getSuff = function (type) {
  if (funcs.DATA_SPEC[type] && funcs.DATA_SPEC[type].suff) {
    return funcs.DATA_SPEC[type].suff
  } else if (type === 'feelsLike') {
    return '°'
  }
  return ''
}
funcs.getSuffForUser = R.curry(function (user, param) {
  const unitParam = funcs.getMasterUnitParam(param)
  const userUnitI = funcs.getUserUnitI(param, user)
  const origSuff = funcs.getSuff(unitParam || param)
  if (userUnitI > 0) {
    return origSuff.replace(funcs.DATA_SPEC[unitParam].units[0], funcs.DATA_SPEC[unitParam].units[userUnitI])
  }
  return origSuff
})
funcs.getSpokenSuffForUser = R.curry(function (user, param) {
  if (/humidity/.test(param)) {
    return 'percent'
  }
  if (/^pm25/.test(param)) {
    return 'micrograms per cubic meter'
  }
  const unitParam = funcs.getMasterUnitParam(param)
  const userUnitI = funcs.getUserUnitI(param, user)
  if (unitParam) return funcs.DATA_SPEC[unitParam].spokenUnits[userUnitI]
  return ''
})
funcs.WIDGET_CONFIG = {
  temp: {
    title: 'Outdoor',
    priority: 0,
    keywords: [
      'temperature',
      'outdoor',
      'humidity',
      'dew',
      'feels',
      'eclipse'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'tempf'],
        ['currentDevice', 'lastData', 'humidity'],
        ['currentDevice', 'lastData', 'dateutc'],
        ['user', 'info', 'settings', 'tempf'],
        ['device', 'yesterdayData']
      ],
      component: 'TempWidget'
    }
  },
  tempkestrel: {
    title: 'Ambient',
    priority: 0,
    keywords: [
      'temperature'
    ],
    share: {
      social: false,
      paths: [
        ['currentDevice', 'lastData', 'tempf'],
        ['currentDevice', 'lastData', 'dateutc']
      ],
      component: 'GenericWidget'
    }
  },
  wind: {
    title: 'Wind',
    priority: 1,
    keywords: [
      'wind',
      'direction'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'winddir'],
        ['currentDevice', 'lastData', 'windspeedmph'],
        ['user', 'info', 'settings', 'windspeedmph'],
        ['currentDevice', 'lastData', 'windgustmph']
      ],
      component: 'WindWidget'
    }
  },
  rain: {
    title: 'Rainfall',
    priority: 2,
    keywords: [
      'rain'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'hourlyrainin'],
        ['currentDevice', 'lastData', 'dailyrainin'],
        ['currentDevice', 'lastData', 'weeklyrainin'],
        ['currentDevice', 'lastData', 'monthlyrainin'],
        ['currentDevice', 'lastData', 'hourlyrainin'],
        ['user', 'info', 'settings', 'windspeedmph'],
        ['currentDevice', 'lastData', 'lastRain']
      ],
      component: 'RainWidget'
    }
  },
  pressure: {
    title: 'Pressure',
    priority: 3,
    keywords: [
      'pressure',
      'barometer',
      'barometric'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'humidity'],
        ['currentDevice', 'lastData', 'dateutc'],
        ['device', 'pressureWidgetLastHour']
      ],
      component: 'PressureWidget'
    }
  },
  humidity: {
    title: 'Humidity',
    priority: 3.5,
    keywords: [
      'humidity',
      'moisture'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'humidity'],
        ['currentDevice', 'lastData', 'dateutc'],
        ['device', 'yesterdayData']
      ],
      component: 'HumidityWidget'
    }
  },
  uv: {
    title: 'UV Index',
    priority: 5,
    keywords: [
      'uv',
      'index',
      'ultra',
      'violet',
      'sun',
      'eclipse'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'uv']
      ],
      component: 'UvWidget'
    }
  },
  solar: {
    title: 'Solar Radiation',
    priority: 6,
    keywords: [
      'solar',
      'sun',
      'radiation',
      'eclipse'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'solarradiation'],
        ['user', 'info', 'settings', 'solarradiation'],
        ['currentDevice', 'lastData', 'hl', 'solarradiation', 'h']
      ],
      component: 'SolarWidget'
    }
  },
  air: {
    title: 'PM2.5 Outdoor',
    priority: 6.5,
    keywords: [
      'air',
      'particulate',
      'matter',
      'smog',
      'pm2.5'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'pm25'],
        ['currentDevice', 'lastData', 'pm25_24h']
      ],
      component: 'AirWidget'
    }
  },
  lightning: {
    title: 'Lightning',
    priority: 6.55,
    keywords: [
      'lightning',
      'strikes',
      'thunder'
    ],
    share: {
      social: true,
      paths: [
      ],
      component: 'LightningWidget'
    }
  },
  indoor: {
    title: 'Indoor',
    priority: 6.6,
    keywords: [
      'temperature',
      'indoor',
      'humidity'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'tempinf'],
        ['user', 'info', 'settings', 'tempf'],
        ['currentDevice', 'lastData', 'humidityin']
      ],
      component: 'IndoorWidget'
    }
  },
  aqin: {
    title: 'Air Quality Index',
    priority: 6.65,
    keywords: [
      'air',
      'indoor',
      'particulate',
      'matter',
      'smog',
      'pm2.5'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'pm10_in_24h_aqin'],
        ['currentDevice', 'lastData', 'pm25_in_24h_aqin'],
        ['currentDevice', 'lastData', 'aqi_pm25_24h_aqin'],
        ['currentDevice', 'lastData', 'aqi_pm10_24h_aqin'],
        ['currentDevice', 'lastData', 'pm25_in_aqin'],
        ['currentDevice', 'lastData', 'pm10_in_aqin'],
        ['currentDevice', 'lastData', 'hl', 'pm10_in_aqin'],
        ['currentDevice', 'lastData', 'hl', 'pm25_in_aqin']
      ]
    }
  },
  airin: {
    title: 'PM2.5 Indoor',
    priority: 6.7,
    keywords: [
      'air',
      'indoor',
      'particulate',
      'matter',
      'smog',
      'pm2.5'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'pm25_in'],
        ['currentDevice', 'lastData', 'pm25_in_24h']
      ],
      component: 'AirWidget'
    }
  },
  co2: {
    title: 'Indoor Air Quality',
    priority: 6.75,
    keywords: [
      'air',
      'indoor',
      'particulate',
      'matter',
      'co2',
      'carbon dioxide',
      'parts per million'
    ],
    share: {
      social: true,
      paths: [
        ['currentDevice', 'lastData', 'co2_in_aqin'],
        ['currentDevice', 'lastData', 'pm_in_temp_aqin'],
        ['currentDevice', 'lastData', 'pm_in_humidity_aqin']
      ]
    }
  },
  leak: {
    title: 'Leak Detector',
    priority: 6.8,
    keywords: [
      'leak',
      'water'
    ],
    fields: [
      'leak1',
      'leak2',
      'leak3',
      'leak4'
    ]
  },
  sensors: {
    title: 'Sensors',
    priority: 7,
    keywords: [
      'sensors',
      'temperature',
      'humidity'
    ],
    fields: [
      'temp1f',
      'temp2f',
      'temp3f',
      'temp4f',
      'temp5f',
      'temp6f',
      'temp7f',
      'temp8f',
      'temp9f',
      'temp10f',
      'humidity1',
      'humidity2',
      'humidity3',
      'humidity4',
      'humidity5',
      'humidity6',
      'humidity7',
      'humidity8',
      'humidity9',
      'humidity10'
    ]
  },
  temp1f: {
    title: 'Sensor 1',
    priority: 7.01,
    keywords: [
      'sensor'
    ]
  },
  soiltemp1: {
    title: 'Soil 1',
    priority: 7.01,
    keywords: [
      'soil',
      'sensor'
    ],
    share: {
      social: true,
      component: 'SoilWidget'
    }
  },
  temp2f: {
    title: 'Sensor 2',
    priority: 7.02,
    keywords: [
      'sensor'
    ]
  },
  soiltemp2: {
    title: 'Soil 2',
    priority: 7.02,
    keywords: [
      'soil',
      'sensor'
    ],
    share: {
      social: true,
      paths: [
      ],
      component: 'SoilWidget'
    }
  },
  temp3f: {
    title: 'Sensor 3',
    priority: 7.03,
    keywords: [
      'sensor'
    ]
  },
  soiltemp3: {
    title: 'Soil 3',
    priority: 7.03,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  temp4f: {
    title: 'Sensor 4',
    priority: 7.04,
    keywords: [
      'sensor'
    ]
  },
  soiltemp4: {
    title: 'Soil 4',
    priority: 7.04,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  temp5f: {
    title: 'Sensor 5',
    priority: 7.05,
    keywords: [
      'sensor'
    ]
  },
  soiltemp5: {
    title: 'Soil 5',
    priority: 7.05,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  temp6f: {
    title: 'Sensor 6',
    priority: 7.06,
    keywords: [
      'sensor'
    ]
  },
  soiltemp6: {
    title: 'Soil 6',
    priority: 7.06,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  temp7f: {
    title: 'Sensor 7',
    priority: 7.07,
    keywords: [
      'sensor'
    ]
  },
  soiltemp7: {
    title: 'Soil 7',
    priority: 7.07,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  temp8f: {
    title: 'Sensor 8',
    priority: 7.08,
    keywords: [
      'sensor'
    ]
  },
  soiltemp8: {
    title: 'Soil 8',
    priority: 7.08,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  temp9f: {
    title: 'Sensor 9',
    priority: 7.09,
    keywords: [
      'sensor'
    ]
  },
  soiltemp9: {
    title: 'Soil 9',
    priority: 7.09,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  temp10f: {
    title: 'Sensor 10',
    priority: 7.10,
    keywords: [
      'sensor'
    ]
  },
  soiltemp10: {
    title: 'Soil 10',
    priority: 7.10,
    keywords: [
      'soil',
      'sensor'
    ],
    share: {
      social: true,
      component: 'SoilWidget'
    }
  },
  soiltemp11: {
    title: 'Soil 11',
    priority: 7.101,
    keywords: [
      'soil',
      'sensor'
    ],
    share: {
      social: true,
      component: 'SoilWidget'
    }
  },
  soiltemp12: {
    title: 'Soil 12',
    priority: 7.102,
    keywords: [
      'soil',
      'sensor'
    ],
    share: {
      social: true,
      component: 'SoilWidget'
    }
  },
  soiltemp13: {
    title: 'Soil 13',
    priority: 7.103,
    keywords: [
      'soil',
      'sensor'
    ],
    share: {
      social: true,
      component: 'SoilWidget'
    }
  },
  soiltemp14: {
    title: 'Soil 14',
    priority: 7.104,
    keywords: [
      'soil',
      'sensor'
    ],
    share: {
      social: true,
      component: 'SoilWidget'
    }
  },
  soiltemp15: {
    title: 'Soil 10',
    priority: 7.105,
    keywords: [
      'soil',
      'sensor'
    ],
    share: {
      social: true,
      component: 'SoilWidget'
    }
  },
  soiltemp16: {
    title: 'Soil 16',
    priority: 7.106,
    keywords: [
      'soil',
      'sensor'
    ],
    share: {
      social: true,
      component: 'SoilWidget'
    }
  },
  soiltens1: {
    title: 'Soil Tension 1',
    priority: 7.50,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  soiltens2: {
    title: 'Soil Tension 2',
    priority: 7.51,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  soiltens3: {
    title: 'Soil Tension 3',
    priority: 7.52,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  soiltens4: {
    title: 'Soil Tension 4',
    priority: 7.53,
    keywords: [
      'soil',
      'sensor'
    ]
  },
  leafwet1x: {
    title: 'Leaf Wetness',
    priority: 7.6,
    keywords: [
      'leaf',
      'sensor'
    ]
  },
  leafwetness1: {
    title: 'Leaf Wetness 1',
    priority: 7.6,
    keywords: [
      'leaf',
      'sensor'
    ]
  },
  leafwetness2: {
    title: 'Leaf Wetness 2',
    priority: 7.6,
    keywords: [
      'leaf',
      'sensor'
    ]
  },
  leafwetness3: {
    title: 'Leaf Wetness 3',
    priority: 7.6,
    keywords: [
      'leaf',
      'sensor'
    ]
  },
  leafwetness4: {
    title: 'Leaf Wetness 4',
    priority: 7.6,
    keywords: [
      'leaf',
      'sensor'
    ]
  },
  leafwetness5: {
    title: 'Leaf Wetness 5',
    priority: 7.6,
    keywords: [
      'leaf',
      'sensor'
    ]
  },
  leafwetness6: {
    title: 'Leaf Wetness 6',
    priority: 7.6,
    keywords: [
      'leaf',
      'sensor'
    ]
  },
  leafwetness7: {
    title: 'Leaf Wetness 7',
    priority: 7.6,
    keywords: [
      'leaf',
      'sensor'
    ]
  },
  leafwetness8: {
    title: 'Leaf Wetness 8',
    priority: 7.6,
    keywords: [
      'leaf',
      'sensor'
    ]
  },
  gdd: {
    title: 'Growing Degree Days',
    priority: 7.71,
    keywords: [
      'growing',
      'degree',
      'days'
    ]
  },
  gdd2: {
    title: 'Growing Degree Days',
    priority: 7.71,
    keywords: [
      'growing',
      'degree',
      'days'
    ]
  },
  cdd: {
    title: 'Cooling Degree Days',
    priority: 7.71,
    keywords: [
      'cooling',
      'heating',
      'degree',
      'days'
    ]
  },
  hdd: {
    title: 'Heating Degree Days',
    priority: 7.71,
    keywords: [
      'heating',
      'degree',
      'days'
    ]
  },
  // evapotranspiration: {
  // etos: {
  //   title: 'Evapotranspiration',
  //   priority: 7.72,
  //   keywords: [
  //     'evapotranspiration'
  //   ]
  // },
  etrs: {
    title: 'Evapotranspiration',
    priority: 7.73,
    keywords: [
      'evapotranspiration'
    ]
  },
  relays: {
    title: 'Relays',
    priority: 8,
    keywords: [
      'relays'
    ],
    fields: [
      'relay1',
      'relay2',
      'relay3',
      'relay4',
      'relay5',
      'relay6',
      'relay7',
      'relay8',
      'relay9',
      'relay10'
    ]
  },
  battery: {
    title: 'Batteries',
    priority: 8.5,
    keywords: [
      'battery',
      'level'
    ],
    fields: [
      'battout',
      'battin',
      'batt1',
      'batt2',
      'batt3',
      'batt4',
      'batt5',
      'batt6',
      'batt7',
      'batt8',
      'batt9',
      'batt10',
      'battr1',
      'battr2',
      'battr3',
      'battr4',
      'battr5',
      'battr6',
      'battr7',
      'battr8',
      'battr9',
      'battr10',
      'batt_25',
      'batt_25in',
      'batleak1',
      'batleak2',
      'batleak3',
      'batleak4',
      'batt_lightning'
    ]
  },
  forecast: {
    title: 'Forecast',
    priority: 8.51,
    keywords: [ 
      'five',
      'day',
      'forecast',
      'seven',
      'tomorrow',
      '5',
      '7',
      'report'
    ],
    share: {
      social: true
    }
  },
  map: {
    title: 'Map',
    priority: 8.52,
    keywords: [
      'map',
      'location'
    ]
  },
  webcam: {
    title: 'Webcam',
    priority: 8.53,
    keywords: [
      'webcam',
      'camera',
      'live',
      'picture'
    ]
  },
  sunMoon: {
    title: 'Sun / Moon',
    priority: 8.6,
    keywords: [
      'sun',
      'moon',
      'solar',
      'lunar',
      'sunrise',
      'astronomy'
    ]
  },
  stats: {
    title: 'Dev stats',
    priority: 9,
    keywords: [
      'stats'
    ]
  },
  view: {
    title: 'Quick View',
    priority: 10,
    keywords: [
      'quick',
      'view'
    ]
  },
  wbgt: {
    title: 'WBGT',
    priority: 11,
    keywords: [
      'wbgt'
    ]
  },
  heatindex: {
    title: 'Heat Index',
    priority: 12,
    keywords: [
      'heatindex'
    ]
  },
  pig: {
    title: 'PIG',
    priority: 11,
    keywords: [
      'pig'
    ]
  },
  crosswind: {
    title: 'Crosswind/Headwind',
    priority: 11
  },
  airdensity: {
    title: 'Air Density',
    priority: 11
  },
  dischargerate: {
    title: 'Discharge Rate',
    priority: 12,
    keywords: [
      'dischargerate'
    ],
    share: {
      social: false,
      paths: [
        ['currentDevice', 'lastData', 'dischargeft3s'],
        ['currentDevice', 'lastData', 'dateutc']
      ],
      component: 'DischargeRateWidget'
    }
  },
  gaugeheight: {
    title: 'Gauge Height',
    priority: 12,
    keywords: [
      'gaugeheight'
    ],
    share: {
      social: false,
      paths: [
        ['currentDevice', 'lastData', 'gaugeheightft'],
        ['currentDevice', 'lastData', 'dateutc']
      ],
      component: 'GaugeHeightWidget'
    }
  },
  waterph: {
    title: 'Water pH',
    priority: 12,
    keywords: [
      'waterph'
    ],
    share: {
      social: false,
      paths: [
        ['currentDevice', 'lastData', 'watermeasph'],
        ['currentDevice', 'lastData', 'dateutc']
      ],
      component: 'WaterPhWidget'
    }
  },
  waternitrates: {
    title: 'Water Nitrates',
    priority: 12,
    keywords: [
      'waternitrates'
    ],
    share: {
      social: false,
      paths: [
        ['currentDevice', 'lastData', 'waternitratesmgl'],
        ['currentDevice', 'lastData', 'dateutc']
      ],
      component: 'WaterNitratesWidget'
    }
  },
}
// kestrel simple widgets
// a simple widget just displays the single parameter
funcs.simpleWidgets = R.pipe(
  R.toPairs,
  R.map((arr) => {
    if (arr[1].simpleWidget) {
      return arr[0]
    }
    return null
  }),
  R.filter(R.identity)
)(funcs.DATA_SPEC)

funcs.simpleWidgets.forEach((param) => {
  funcs.WIDGET_CONFIG[param] = {
    title: funcs.DATA_SPEC[param].label,
    priority: 12
  }
})

// simple graphs show a generic graph of the parameter
funcs.simpleGraphs = R.pipe(
  R.toPairs,
  R.map((arr) => {
    if (arr[1].simpleGraph) {
      return arr[0]
    }
    return null
  }),
  R.filter(R.identity)
)(funcs.DATA_SPEC)

// add soiltemp share
R.range(1, 11).forEach(function (i) {
  funcs.WIDGET_CONFIG[`temp${i}f`].share = {
    paths: [
      ['currentDevice', 'lastData', `temp${i}f`],
      ['user', 'info', 'settings', 'tempf'],
      ['currentDevice', 'lastData', `humidity${i}`],
      ['sensor', R.always(i)]
    ],
    social: true,
    component: 'IndoorWidget'
  }
  funcs.WIDGET_CONFIG['soiltemp' + i].share = {
    social: true,
    paths: [
      ['currentDevice', 'lastData', 'soiltemp' + i],
      ['user', 'info', 'settings', 'soilhum1'],
      ['currentDevice', 'lastData', 'soilhum' + i],
      ['sensor', R.always(i)]
    ],
    component: 'SoilWidget'
  }
})
const dataHas = R.curry(function (data, paths) {
  return R.pipe(
    R.groupWith(R.F),
    R.all((pth) => {
      return R.path(pth, data) || R.path(pth, data) === 0
    })
  )(Array.isArray(paths) ? paths : [paths])
})
const nonWeatherStationTypes = [
  '5000',
  '5100',
  '5200',
  '5400',
  '5500',
  '5700',
  'Kestrel DROP 1',
  'Kestrel DROP 2',
  'Kestrel D2 AG',
  'Kestrel DROP 3',
  'Kestrel D3 FIRE',
  '3550AG',
  '3550FW',
  'usgs-'
]
funcs.deviceHasStationType = R.curry(function (stationTypes, theDevice) {
  const stationType = R.path(['lastData', 'stationtype'], theDevice)
  return stationType && stationTypes.some(st => stationType.includes(st))
})
funcs.deviceIsAKestrel = funcs.deviceHasStationType(nonWeatherStationTypes)

const getWidgetTitle = function (device, type) {
  let val
  // use an alternate widget
  if (funcs.deviceIsAKestrel(device) && type === 'temp') {
    val = funcs.WIDGET_CONFIG.tempkestrel.title
  // use the same widgets, but alter it's title
  } else if (funcs.deviceIsAKestrel(device) && type === 'humidity') {
    val = 'Relative Humidity'
  } else {
    val = funcs.WIDGET_CONFIG[type] && funcs.WIDGET_CONFIG[type].title ? funcs.WIDGET_CONFIG[type].title : type.replace(/(\d+)/, ' $1')
  }
  const setting = R.path(['settings', type], device)
  if (setting) {
    val = R.path(['title'], setting) || val
  }
  return val
}
funcs.getWidgetTitle = getWidgetTitle
funcs.customParamLabel = function (prop, theDevice) {
  if (/temp\d/.test(prop)) {
    return getWidgetTitle(theDevice, prop) + ' Temperature'
  } else if (/pm25_in/.test(prop)) {
    return getWidgetTitle(theDevice, 'airin') + (/_24/.test(prop) ? ' 24 Hour Average' : '')
  } else if (/pm25/.test(prop)) {
    return getWidgetTitle(theDevice, 'air') + (/_24/.test(prop) ? ' 24 Hour Average' : '')
  } else if (prop === 'tempf') {
    return getWidgetTitle(theDevice, 'temp') + ' Temperature'
  } else if (prop === 'battout') {
    return getWidgetTitle(theDevice, 'temp') + ' Battery'
  } else if (prop === 'batt_25') {
    return getWidgetTitle(theDevice, 'air') + ' Battery'
  } else if (prop === 'battin') {
    return getWidgetTitle(theDevice, 'indoor') + ' Battery'
  } else if (prop === 'tempinf') {
    return getWidgetTitle(theDevice, 'indoor') + ' Temperature'
  } else if (prop === 'humidity') {
    return getWidgetTitle(theDevice, 'humidity')
  } else if (prop === 'humidityin') {
    return getWidgetTitle(theDevice, 'indoor') + ' Humidity'
  } else if (prop === 'feelsLikein') {
    return getWidgetTitle(theDevice, 'indoor') + ' Feels Like'
  } else if (prop === 'dewPointin') {
    return getWidgetTitle(theDevice, 'indoor') + ' Dew Point'
  } else if (prop === 'sunMoon') {
    return getWidgetTitle(theDevice, 'sunMoon')
  } else if (/relay/.test(prop)) {
    return getWidgetTitle(theDevice, prop)
  } else if (/^leak/.test(prop)) {
    return getWidgetTitle(theDevice, prop)
  } else if (/^batleak/.test(prop)) {
    return getWidgetTitle(theDevice, prop.replace('bat', '')) + ' Battery'
  } else if (/battr\d/.test(prop)) {
    return getWidgetTitle(theDevice, `relay${prop.match(/\d+/)[0]}`) + ' Battery'
  } else if (/batt\d/.test(prop)) {
    const tempKey = `temp${prop.match(/\d+/)[0]}f`
    const soilOrTemp = dataHas(theDevice.lastData, tempKey) ? tempKey : `soiltemp${prop.match(/\d+/)[0]}`
    return getWidgetTitle(theDevice, soilOrTemp) + ' Battery'
  } else if (/soilhum\d/.test(prop)) {
    return getWidgetTitle(theDevice, `soiltemp${prop.match(/\d+/)[0]}`) + ' Moisture'
  } else if (/humidity\d/.test(prop)) {
    return getWidgetTitle(theDevice, `temp${prop.match(/\d+/)[0]}f`) + ' Humidity'
  } else if (/feelsLike\d/.test(prop)) {
    return getWidgetTitle(theDevice, `temp${prop.match(/\d+/)[0]}f`) + ' Feels Like'
  } else if (/dewPoint\d/.test(prop)) {
    return getWidgetTitle(theDevice, `temp${prop.match(/\d+/)[0]}f`) + ' Dew Point'
  }
  if (funcs.DATA_SPEC[prop]) {
    return funcs.DATA_SPEC[prop].label
  }
  if (prop === 'feelsLike') {
    return 'Feels Like'
  }
  if (prop === 'dewPoint') {
    return 'Dew Point'
  }
  if (prop === 'rain') {
    return 'Rainfall'
  }
  if (prop === 'rainTotal') {
    return 'Rain Total'
  }
  if (prop === 'notReporting') {
    return 'Device not reporting for 20 minutes'
  }
}
module.exports = funcs
