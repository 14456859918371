import React, { PureComponent } from 'react';
import classNames from 'classnames'
import bindAllActions from '../../common/bindAllActions'
import FormattedDataPoint from './FormattedDataPoint'
import { timeFormatForUser, getHlAvg, convertUnitForUser, convertUnitInverse, getMasterUnitParam, getUserUnitI, isWindDir, reduceSummariesForType, getLabel, getDisplayKeys, getDateTz, currentDeviceHasSummaries, isSomething, theDeviceIsMine, isAdmin } from '../../common/ambient'
import PropTypes from 'prop-types';
import { path, clone } from 'ramda'

class SummaryTableCell extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    prop: PropTypes.string,
    onClick: PropTypes.func, // only used for non-editable cells
    doc: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),
    user: PropTypes.object,
    details: PropTypes.bool
  }
  state = {
    
  }

  refCallback = el => {
    const { height } = this.state
    if (!height && el) {
      this.setState({
        height: el.getBoundingClientRect().height
      })
    }
  }

  _id() {
    const { doc, type, prop } = this.props
    return doc.dateutc + type + prop
  }
  _needsConversion(){
    return getMasterUnitParam(this.props.type)
  }

  _docId() {
    return this.props.doc.loc
  }

  render() {
    const { st8Value, st8Date, height } = this.state
    const { onClick, deviceActions, device, type, prop, doc, user, details } = this.props
    const { editingSummaryCell } = device
    const getDate = getDateTz(moment, device)

    const editing = editingSummaryCell === this._id()

    let hl = doc[type]
    let field, value, date
    let editable = this._docId()
    let theDate = false
    const nada = <span className="nada">- -</span>
    // rain params shouldn't have lows
    if (
      (/rain/.test(type) && prop === 'l') ||
      ((/rain/.test(type) && !/hourly/.test(type)) && prop === 'avg' && details)
    ) {
      hl = null 
    }
    // rain total
    if (type === 'rainTotal' && prop ==='h') {
      value = path(['dailyrainin', 'hs'], doc)
      field = <FormattedDataPoint type={'dailyrainin'} value={value} />

    } else if (!hl) {
      field = nada 
      editable = false
      
    // avg
    } else if (prop === 'avg') {
      value = getHlAvg(doc, type)
      if (isWindDir(type)) {
        field = value 
      } else {
        field = <FormattedDataPoint type={type} value={value} />
      }
      
    // highs & lows
    } else {
      if (isWindDir(type)) {
        editable = false
        field = nada 
      } else {
        value = hl[prop] 
        theDate = getDate(hl[prop + 't'])
        if (!theDate.isValid()) {
          theDate = getDate(doc.dateutc)
        }
        date = theDate.format(details ? timeFormatForUser(user) : 'M/D/YY')
        field = [
          <FormattedDataPoint key={'fdp'} type={type} value={value} />,
          <span className="date" key={'date'}>{date}</span>,
          details ? null : <span className="date" key={'datetime'}>{theDate.format(timeFormatForUser(user))}</span>
        ]
      }
    }
    if (!theDeviceIsMine(device) && !isAdmin(user)) {
      editable = false
    }

    if (details && editable && !editing) {
      return (
        <a ref={this.refCallback} onClick={() => {
          if (!editing) {
            this.setState({
              st8Value: this._needsConversion() ? convertUnitForUser(user, type, value) : value,
              st8Date: theDate ? theDate.format('h:mm a') : null
            })
            deviceActions.editSummaryCell(this._id())
          }
        }} className={classNames("device-summary-table-cell", { editing })}>
          {field}
        </a>
      )
    } else if (editing) {
      let dateInputs
      const closeFn = () => {
        const newStuff = {}
        newStuff[type] = clone(doc[type])
        let newValue = parseFloat(st8Value)
        if (isWindDir(type)) {
          newStuff[type] = st8Value
        } else if (!isNaN(newValue)) {
          // backwards convert it?
          if (this._needsConversion()) {
            const userUnitI = getUserUnitI(type, user)
            newValue = convertUnitInverse(this._needsConversion(), userUnitI, newValue)
          }
          newStuff[type][prop === 'avg' ? 'avg' : prop] = newValue 
        }
        if (st8Date) {
          const amPieces = st8Date.split(' ')
          const pieces = amPieces[0].split(':')
          let hours = parseInt(pieces[0])
          const mins = parseInt(pieces[1])
          if (!isNaN(hours) && hours < 13 && hours > 0 && !isNaN(mins) && mins < 60) {
            if (amPieces[1] === 'am') {
              if (hours === 12) {
                hours = 0
              }
            } else if (hours !== 12) {
              hours += 12
            }
            const dateToUse = hl[prop + 't'] || doc.dateutc
            const newDate = getDate(getDate(dateToUse).format('YYYY-MM-DD') + ' ' + hours.toString().padStart(2, '0') + ':' + mins.toString().padStart(2, '0'))
            newStuff[type][prop + 't'] = newDate.valueOf()
          }
        }
        const newDoc = Object.assign({}, doc, newStuff)
        deviceActions.updateDeviceData(this._docId(), newDoc, {})
        deviceActions.editSummaryCell()
      }
      const handleEnter = evt => {
        if (evt.key === 'Enter') closeFn()
      }
      if (st8Date) {
        const amPieces = st8Date.split(' ')
        const pieces = amPieces[0].split(':')
        dateInputs = <span key={'dates'} className="date">
          <input className="hour" type="text" value={pieces[0]} onKeyPress={handleEnter} onChange={evt => this.setState({ st8Date: evt.target.value + ':' + pieces[1] + ' ' + amPieces[1] })} />
          <span>:</span>
          <input type="text" value={pieces[1]} onKeyPress={handleEnter} onChange={evt => this.setState({ st8Date: pieces[0] + ':' + evt.target.value + ' ' + amPieces[1] })} />
          <select value={amPieces[1]} onChange={evt => {
            this.setState({
              st8Date: pieces[0] + ':' + pieces[1] + ' ' + evt.target.value
            })
          }}>
            <option value={"am"}>am</option>
            <option value={"pm"}>pm</option>
          </select>
        </span>
      }
      field = [
        <input autoFocus key={'input'} type="text" value={st8Value} onKeyPress={handleEnter} onChange={evt => this.setState({ st8Value: evt.target.value })} />,
        dateInputs,
        <a style={{ height }} key={'close'} onClick={closeFn} className="close"><i className="glyphicon glyphicon-ok" /></a>
      ]
    }
    return <span onClick={() => {
      if (theDate && onClick) {
        onClick(theDate)
      }
    }} ref={this.refCallback} className={classNames("device-summary-table-cell", { editing })}>{field}</span>
  }
}
export default bindAllActions(SummaryTableCell)

SummaryTableCell.displayName = 'SummaryTableCell'
